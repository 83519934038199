<div class="restaurant-divider-content d-flex flex-column">
  <div class="restaurant-divider-hours card border-radius-none mt-0 d-flex h-100">
    <div
      [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
      class="restaurant-divider-hours-background"
    >
      <div class="divider-modal-backdrop position-absolute-0"></div>

      <div *ngIf="delayed" class="position-relative w-100 h-100 d-flex flex-column">
        <div class="container overflow-x text-center text-white opacity-1">
          <div class="mt-4rem section-title"> {{ typeCategorySection.title }} </div>

          <div class="mb-4rem mt-2 font-size-0.9rem font-weight-400"> {{ cityName }} </div>
        </div>

        <div class="m-auto"></div>
      </div>
    </div>
  </div>
</div>

<div class="divider-footer bg-white border-bottom-radius-3 p-3">
</div>

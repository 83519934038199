<div class="segmented segmented-strong w-100">
  <button
    *ngFor="let button of buttons; let i = index"
    class="button"
    [ngClass]="{'active': activeIndex === i, 'hold': holdIndex === i}"
    (click)="setActive(i, button)"
    (mousedown)="setHold(i)"
    (mouseup)="clearHold()"
    (mouseleave)="clearHold()"
  >
    <div>
      <span *ngIf="button.count"> {{ button.count }} </span>

      {{ button.label }}
    </div>
  </button>

  <div
    class="segmented-highlight"
    [ngStyle]="{'transform': highlightTransform, 'width': 'calc(' + 100 / buttons.length + '%)' }"
  ></div>
</div>

<div *ngIf="(!hasDiningEmbeds && !hasWalkingEmbeds) || linkVideoMode" class="d-flex w-100 h-100 row m-0">
  <div class="col-6 bg-white d-flex justify-content-center">
    <div [appBackgroundUrl]="backgroundImage ? backgroundImage: ''" class="position-absolute-0">
      <div class="mt-3-5rem font-weight-bold section-title d-flex justify-content-center">
        {{ searchParams.locationName }}
      </div>
    </div>
  </div>

  <div class="col-6 bg-black">
    <div class="bottom-section d-flex flex-column mx-2rem pt-3">
      <app-video-modal-input
        [searchParams]="searchParams"
        [inputPlaceholder]="!submitClicked ? 'Link a video of a dining tour': 'URL required'"
        [textInput]="'For ' + searchParams.locationName"
        [type]="TourType.DINING"
        (videoAdded)="onVideoAdded($event)"
        (videoCanceled)="close()"
      ></app-video-modal-input>
    </div>

    <div class="bottom-section d-flex flex-column mx-2rem pt-3">
      <app-video-modal-input
        [searchParams]="searchParams"
        [inputPlaceholder]="!submitClicked ? 'Link a video of a walking tour': 'URL required'"
        [textInput]="'YouTube • TikTok • Rumble • etc'"
        [type]="TourType.WALKING"
        (videoAdded)="onVideoAdded($event)"
        (videoCanceled)="close()"
      ></app-video-modal-input>
    </div>
  </div>
</div>

<div
  *ngIf="hasDiningEmbeds || hasWalkingEmbeds"
  class="modal-body overflow-y d-flex p-0 bg-black"
>
  <div class="d-flex flex-column w-100">
    <div class="min-h-100vh d-flex justify-content-center w-100">
      <div class="d-flex flex-column w-65rem z-index-1 h-100">
        <h3 class="text-center my-2rem"> {{ searchParams.locationName }} </h3>

        <div
          *ngIf="!hasDiningEmbeds && !hasWalkingEmbeds"
          [appBackgroundUrl]="backgroundImage ? backgroundImage: ''"
          class="position-absolute-0 backdrop-filter-brightness-0-85 cursor-pointer"
        >
          <div class="position-fixed-0 favorites-video-gradient"></div>
        </div>

        <h3 class="text-center text-white my-2rem position-absolute left-0 right-0">
          {{ searchParams.locationName }}
        </h3>

        <div class="target-line cursor-pointer z-index-3">
          <div  #videoList class="row mb-3 mx-4">
            <div *ngIf="hasDiningEmbeds" class="col-6 text-center text-white pl-4">
              <div *ngFor="let embed of locationEmbeds;" class="mt-3">
                <app-embed-video
                  *ngIf="embed.tourType == TourType.DINING"
                  [embed]="embed"
                  (deleteEmbed)="onDeleteDiningEmbed(embed)"
                ></app-embed-video>
              </div>
            </div>

            <div class="col-6 text-center text-white pr-4">
              <div *ngIf="hasWalkingEmbeds">
                <div *ngFor="let embed of locationEmbeds;" class="mt-3">
                  <app-embed-video
                    *ngIf="embed.tourType == TourType.WALKING"
                    [embed]="embed"
                    (deleteEmbed)="onDeleteWalkingEmbed(embed)"
                  ></app-embed-video>
                </div>
              </div>

              <ng-container *ngIf="!toggleInput">
                <div class="bottom-section d-flex flex-column mx-2rem pt-3">
                  <app-video-modal-input
                    [searchParams]="searchParams"
                    [inputPlaceholder]="!submitClicked ? 'Link a video of a dining tour': 'URL required'"
                    [textInput]="'For ' + searchParams.locationName"
                    [type]="TourType.DINING"
                    (videoAdded)="onVideoAdded($event)"
                    (videoCanceled)="onVideoCanceled()"
                  ></app-video-modal-input>
                </div>

                <div class="bottom-section d-flex flex-column mx-2rem pt-3">
                  <app-video-modal-input
                    [searchParams]="searchParams"
                    [inputPlaceholder]="!submitClicked ? 'Link a video of a walking tour': 'URL required'"
                    [textInput]="'YouTube • TikTok • Rumble • etc'"
                    [type]="TourType.WALKING"
                    (videoAdded)="onVideoAdded($event)"
                    (videoCanceled)="onVideoCanceled()"
                  ></app-video-modal-input>
                </div>
              </ng-container>
            </div>

            <div
              *ngIf="toggleInput"
              class="col-12 text-center text-white d-flex justify-content-end mt-2"
              (click)="openVideoModal()"
            >
              Link video
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #videoModalRef let-c="close">
  <app-video-modal
    [type]="type"
    [searchParams]="searchParams"
    (videoAdded)="onVideoAdded($event)"
  ></app-video-modal>
</ng-template>

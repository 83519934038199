<div
  class="section-item p-2"
>
  <div *ngIf="section.type === sectionType.OPTION" class="form-row">
    <div class="form-group col-12">
      <input
        [(ngModel)]="section.title"
        type="text"
        class="form-control"
        placeholder="Section name"
        (change)="sectionEdit()"
      />
    </div>
  </div>
  <ng-container *ngFor="let extra of section.extras; let extraIndex = index; let first = first">
    <div class="d-flex d-flex-row">
      <div class="form-group form-group-col flex-fill">
        <input
          [(ngModel)]="extra.name"
          type="text"
          class="form-control"
          (change)="onExtraChange(extra)"
        />
      </div>
      <div class="form-group form-group-col">
        <div class="form-row">
          <div class="col-12 input-group-prepend">
            <span class="input-group-text pr-2">$</span>
            <input
              [ngModel]="extra.price | number: '.2-2'"
              [min]="0"
              [ngClass]="{'is-invalid': extra.name && extra.hasRequiredError}"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              class="form-control form-control-3-digit pl-0"
              (change)="onPriceChange($event, extra)"
            />
            <small class="invalid-feedback">Required</small>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="section.type === sectionType.OPTION" class="p-2">
  <ng-container *appIfIsMobile>
    <div class="form-row">
      <div class="col-6 form-inline custom-select-container mr-2">
        <select
          [(ngModel)]="section.min"
          id="require"
          class="section-min-max form-control"
          (ngModelChange)="sectionEdit()"
        >
          <option *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [ngValue]="i">Require {{ i }}</option>
        </select>

        <i class="icon-v2 arrow-down"></i>
      </div>

      <div *ngIf="section.min == 0" class="col-5 form-inline custom-select-container">
        <select
          [(ngModel)]="section.max"
          id="max"
          class="section-min-max form-control"
          (ngModelChange)="sectionEdit()"
        >
          <option *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [ngValue]="i">Max {{ i }}</option>
        </select>

        <i class="icon-v2 arrow-down"></i>
      </div>
    </div>
  </ng-container>

  <ng-container *appIfIsDesktop>
    <div class="form-row">
      <div class="col-6 form-inline custom-select-container mr-2">
        <div
          ngbDropdown
          appNgbDropdownOnHover
          [placement]="'bottom'"
          class="d-inline-flex cursor-pointer"
        >
          <div
            ngbDropdownToggle
            class="availability-dropdown-toggle flex-content-vc-hc hide-arrow"
          >
            <span class="mr-4"> Require </span>

            <span class="mr-1"> {{ section.min }} </span>

            <i class="icon-v2 arrow-down"></i>
          </div>

          <div
            ngbDropdownMenu
            class="bg-dark h-20rem"
          >
            <a
              appHrefNone
              class="dropdown-item d-inline-flex text-white"
              *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              (click)="sectionChange(i, true)"
            >
              {{ i }}
            </a>
          </div>
        </div>
      </div>

      <div
        *ngIf="section.min == 0"
        class="col-5 form-inline custom-select-container mr-2"
      >
        <div
          ngbDropdown
          appNgbDropdownOnHover
          [placement]="'bottom'"
          class="d-inline-flex cursor-pointer"
        >
          <div
            ngbDropdownToggle
            class="availability-dropdown-toggle flex-content-vc-hc hide-arrow"
          >
            <span class="mr-4"> Max </span>

            <span class="mr-1"> {{ section.max }} </span>

            <i class="icon-v2 arrow-down"></i>
          </div>

          <div
            ngbDropdownMenu
            class="bg-dark h-20rem"
          >
            <a
              appHrefNone
              class="dropdown-item d-inline-flex text-white"
              *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              (click)="sectionChange(i, false)"
            >
              {{ i }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="restaurant-modal-body modal-body d-flex px-0">
  <div class="d-flex w-100">
    <span class="p-0 bg-white">
      <div class="d-flex w-100 h-100 flex-column z-index-1 position-relative bg-post">
        <div class="px-0">
          <div
            class="post-body d-flex flex-column w-100 h-100"
            data-id="{{currentPost.id}}"
          >
            <ng-container *ngIf="currentPost.images.length > 0">
              <img
                *ngIf="currentPost.images.length > 0"
                [src]="currentPost.images[0].path | imgix:{w: 740}"
                class="w-100 h-100 img-min-h-min-w"
              />
            </ng-container>
          </div>
        </div>
      </div>
    </span>

    <div class="font-size-1-1rem w-post d-flex flex-column">
      <div class="row mx-1-85rem">
        <div class="col-2 pl-0 user flex-content-hc">
          <div class="mt-4 user-avatar user-avatar-post rounded-circle">
            <app-user-avatar
              [user]="currentPost.user"
              [widthAndHeight]="100"
              (imageClick)="goToUser(username)"
            ></app-user-avatar>
          </div>
        </div>

        <div class="col-7 d-flex justify-content-start pl-0">
          <div class="mt-1-7rem font-weight-bold text-white">
            <div class="cursor-pointer opacity-07-to-1-on-hover" (click)="goToUser(username)">
              <small *ngIf="!hideCaption" class="username text-white">
                {{ user.username }}
              </small>
            </div>
          </div>
        </div>

        <div class="col-1 mt-23-8px pl-0 pr-3 ml-n3px">
          <app-post-options
            [post]="currentPost"
            [postDesktop]="true"
            (postEdit)="postEdit()"
            (postDeleted)="postDeleted(currentPost)"
            class="opacity-07-to-1-on-hover"
          ></app-post-options>
        </div>

        <div class="col-1 mt-1-8rem pl-0 pr-13px">
          <div
            #dropdownRef
            ngbDropdown
            appNgbDropdownOnHover
            placement="bottom-right"
            (mouseleave)="closeDropdown()"
          >
            <div
              ngbDropdownToggle
              class="flex-content-hc mb-3-5rem cursor-pointer opacity-07-to-1-on-hover hide-arrow sidenav-icons"
            >
              <i class="icon-v2 direct mt-4px"></i>
            </div>

            <div ngbDropdownMenu class="bg-dark backdrop-filter-blur-5">
              <a
                appHrefNone
                class="dropdown-item flex-content-vc"
                (click)="shareSocial(currentPost, user, 'twitter')"
              >
                <span class="flex-fill text-white">Tweet</span>

                <span class="icon-v2 twitter-white scale-08"></span>
              </a>

              <a
                appHrefNone
                class="dropdown-item flex-content-vc"
                (click)="shareSocial(currentPost, user,'facebook')"
              >
                <span class="flex-fill text-white">Post</span>

                <span class="icon-v2 facebook-white scale-08"></span>
              </a>

              <a appHrefNone class="dropdown-item flex-content-vc" (click)="copyLink()">
                <span class="flex-fill text-white">Copy Link</span>

                <span class="icon-v2 chain-white scale-08"></span>
              </a>
            </div>
          </div>
        </div>

        <div class="col-1 mt-1-7rem pl-0 w-45px">
          <app-like
            [object]="currentPost"
            [verb]="getstreamVerb.POST"
            class="like pointer-events-all cursor-pointer mt-n2px"
          ></app-like>
        </div>
      </div>

      <div *ngIf="currentPost.food" class="mx-2rem mb-3 mt-n2">
        <div
          [ngClass]="{'post-caption-collapsed': !captionExpanded}"
          class="post-caption cursor-pointer mt-2"
          (click)="captionExpanded = !captionExpanded"
        >
          <button
            *ngIf="currentPost.food"
            class="btn btn-xs btn-default text-white food-btn pointer-events-all"
            (click)="goToFood()"
          >
            <span *ngIf="currentPost.food">{{ currentPost.food.title }}&nbsp;•&nbsp;</span>{{ currentPost.restaurantTitle }}&nbsp;•&nbsp;{{ currentPost.restaurantCityState }}
          </button>
        </div>
      </div>

      <div
        *ngIf="!editMode && currentPost.caption"
        class="row mx-2rem mb-3 text-white font-size-0-96rem"
        [innerHTML]="currentPost.caption"
      ></div>

      <div *ngIf="editMode" class="post-edit flex-grow-1 d-flex flex-column">
        <div class="mx-2rem font-size-0-96rem">
          <quill-editor
            [(ngModel)]="captionEdited"
            theme="bubble"
            placeholder="Add caption..."
            (onEditorCreated)="setFocus($event)"
          >
            <div quill-editor-toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
            </span>
            </div>
          </quill-editor>
        </div>

        <div class="m-auto"></div>

        <div class="mt-auto row d-flex justify-content-center mb-2rem">
          <button class="btn btn-dark col-5 mx-3 mt-3" (click)="cancelMode()"> Cancel </button>

          <button class="btn btn-primary col-5 mr-3 mt-3" (click)="rightButtonClick()"> Save edit </button>
        </div>
      </div>
    </div>

    <div
      ngbTooltip="Try keyboard arrows!"
      class="btn btn-round arrow-bar left-n3rem top-41vh d-flex align-items-center cursor-pointer justify-content-center"
      (click)="previousPostClick()"
      (mouseover)="arrowHover = true"
      (mouseleave)="arrowHover = false"
    >
      <div class="icon-v2 arrow-forward-white side-arrow-right cursor-pointer"></div>
    </div>

    <div
      class="btn btn-round arrow-bar right-n3rem top-41vh d-flex align-items-center cursor-pointer justify-content-center"
      (click)="nextPostClick()"
      (mouseover)="arrowHover = true"
      (mouseleave)="arrowHover = false"
    >
      <div class="icon-v2 arrow-forward-white rotate-180 side-arrow-right cursor-pointer"></div>
    </div>
  </div>
</div>

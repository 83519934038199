import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CountryWithChild } from '../../../domains/country-with-child';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { DistanceSliderPosition } from '../../../models/distance-slider-position';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { NavigatorService } from '../../../services/navigator.service';
import { SearchParamsService } from '../../../services/search-params.service';

@Component({
  selector: 'app-navbar-top-explore',
  templateUrl: './navbar-top-explore.component.html',
  styleUrls: [
    '../navbar.shared.scss',
    './navbar-top-explore.component.scss'
  ]
})
export class NavbarTopExploreComponent implements OnInit {
  @Input() menuHours = false;
  @Input() country: CountryWithChild;
  @Input() searchParams: SearchParams;
  @Input() findMeLoader: boolean;
  @Input() searchResult: SearchResult;

  @Output() onSearchClicked = new EventEmitter<boolean>();
  @Output() restaurantClick = new EventEmitter<any>();

  distanceSliderPosition = DistanceSliderPosition;

  searchModalCloseDisableLocalStorage = new BooleanLocalStorage(LocalStorageKey.SEARCH_MODAL_CLOSE_DISABLE);

  constructor(
    private searchParamsService: SearchParamsService,
    private navigatorService: NavigatorService
  ) {
  }

  ngOnInit() {
  }

  searchModalClicked() {
    this.onSearchClicked.emit(true);
  }

  goToCityRestaurants(event) {
    event.preventDefault();
    event.stopPropagation();

    this.searchParams = this.searchParamsService.removeGeoLocationFromPath(this.searchParams);

    setTimeout(() => {
      this.searchModalCloseDisableLocalStorage.setItem(true);

      this.navigatorService.goToUrl(this.searchParams.path).then(() => {
        this.searchModalCloseDisableLocalStorage.setItem(false);
      });
    }, 100);
  }
}

<div class="semicircle-notch-menu">
  <div class="semicircle-notch">
    <span class="circle"></span>
  </div>

  <div class="semicircle-inset flex-content-vc-hc">
    <a
      [ngClass]="{'btn-primary': !rotateMainLogo && (!searchParamsGeoLocation && !isEditMode && isLoggedIn && userRestaurantsLength > 0)}"
      class="btn btn-default btn-circle btn-circle-lg flex-content-vc-hc"
      (click)="onMenuClick()"
    >
      <ng-content></ng-content>
    </a>
  </div>
</div>

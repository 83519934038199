<nav class="w-100 bg-white z-index-10 position-fixed top-0">
  <div class="row py-2 d-flex align-items-center mx-0 px-0">
    <div class="search-desktop-main flex-fill">
      <div class="search-desktop-category-section search-desktop-category-section-first">
        <div class="search-desktop-category">
          <div class="search-desktop-restaurant-header">
            <div class="flex-fill">
              <div class="d-flex mx-0 w-100 align-items-center justify-content-end">
                <a appHrefNone class="icon-v2 mmmm-red scale-09 mx-3 z-index-10"></a>

                <div class="form-control-style bg-container">
                  <i class="icon-v2 ml-3 scale-0-7 position-absolute top-17px"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="search-desktop-sidebar">
        <div class="search-desktop-controls">
          <div class="pt-2">
            <div class="search-desktop-restaurants w-100 d-flex">
              <ngx-skeleton-loader
                count="1"
                animation="progress-dark"
                [theme]="{
                  'display': 'block',
                  'height': '24px',
                  'width': '35px',
                  'border-radius': '1rem',
                  'margin-top': '9px',
                  'background-color': '#ffffff20',
                }"
              ></ngx-skeleton-loader>

              <ngx-skeleton-loader
                count="1"
                animation="progress-dark"
                [theme]="{
                  'display': 'block',
                  'height': '24px',
                  'width': '80px',
                  'border-radius': '1rem',
                  'margin-left': '16px',
                  'margin-top': '9px',
                  'background-color': '#ffffff20'
                }"
              ></ngx-skeleton-loader>

              <ngx-skeleton-loader
                count="1"
                animation="progress-dark"
                [theme]="{
                  'display': 'block',
                  'height': '24px',
                  'width': '80px',
                  'border-radius': '1rem',
                  'margin-left': '16px',
                  'margin-top': '9px',
                  'background-color': '#ffffff20'
                }"
              ></ngx-skeleton-loader>

              <div class="search-desktop-topnav-icons d-flex align-items-center justify-content-end h-37-6px flex-fill">
                <a
                  appHrefNone
                  class="icon call cursor-pointer"
                ></a>

                <a
                  appHrefNone
                  class="icon-v2 play cursor-pointer ml-2rem mb-0-35rem"
                ></a>

                <a
                  appHrefNone
                  class="icon-v2 profile-dark cursor-pointer ml-2rem"
                ></a>

                <a appHrefNone class="icon-v2 hamburger-dark cursor-pointer ml-2rem mr-2rem"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

import { AfterViewInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models';
import { environment } from '../../../../environments/environment';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { IsModalOpen } from '../../../decorators/is-modal-open.decorator';
import { IsSidenavMouseOver } from '../../../decorators/is-sidenav-mouse-over.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { RouteParamsValue } from '../../../decorators/route-params-value.decorator';
import { Food } from '../../../domains/food';
import { Menu } from '../../../domains/menu';
import { Restaurant } from '../../../domains/restaurant';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorage } from '../../../local-storage/local-storage';
import { Basket } from '../../../models/basket/basket';
import { DeviceType } from '../../../models/enums/device-type';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { RouteParamsType } from '../../../models/enums/route-params-type';
import { SidenavType } from '../../../models/enums/sidenav-type';
import { TagType } from '../../../models/enums/tag-type';
import { ViewType } from '../../../models/enums/view-type';
import { LocationFavorites } from '../../../models/location-favorites';
import { RestaurantRouteParams } from '../../../models/route-params/restaurant-route-params';
import { SearchParams } from '../../../models/search-params';
import { SearchResult } from '../../../models/search-result';
import { UnsplashImage } from '../../../models/unsplash-image';
import { BasketService } from '../../../services/basket.service';
import { ExploreService } from '../../../services/explore.service';
import { FavoritesService } from '../../../services/favorites.service';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';
import { UnsplashService } from '../../../services/unsplash.service';
import { ViewService } from '../../../services/view.service';
import { DomUtils } from '../../../utils/dom-utils';

@Component({
  selector: 'app-restaurant-desktop',
  templateUrl: './restaurant-desktop.component.html',
  styleUrls: ['./restaurant-desktop.component.scss']
})
export class RestaurantDesktopComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() restaurant: Restaurant;
  @Input() menus: Menu[];
  @Input() isSearching: boolean;

  @ViewChild('restaurantDesktop') restaurantDesktop: ElementRef;
  @ViewChild('restaurantModalRef') restaurantModalRef: ElementRef;
  @ViewChild('foodGalleryModalRef') foodGalleryModalRef: ElementRef;

  @IsModalOpen(ModalType.LOGIN) isLoginModalOpen: boolean;
  @IsModalOpen(ModalType.RESTAURANT_SQUARE) isRestaurantModalOpen: boolean;
  @IsModalOpen(ModalType.SUBSCRIBE_DESKTOP) isSubscribeModalOpen: boolean;

  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;

  @CurrentUser() currentUser: User;

  @RouteParamsValue(RouteParamsType.RESTAURANT) restaurantRouteParams: RestaurantRouteParams;

  readonly marginFromPreviousSectionsLastFood = 94;

  menuActive: Menu;
  tagType = TagType;
  viewType = ViewType;
  logoMouseOver = false;
  isEditModeLocalStorage: BooleanLocalStorage;
  isEditMode: boolean;
  defaultAvatar: string;
  basket: Basket;
  isDataReady = false;
  defaultMenuImage: string;
  backgroundImage: UnsplashImage;
  favoriteClickSubjectSubscription: Subscription;
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);
  searchParams: SearchParams;
  locationFavorites: LocationFavorites;
  searchResult: SearchResult;

  phantomFood: Food;

  constructor(
    private navigatorService: NavigatorService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private basketService: BasketService,
    private notificationService: NotificationService,
    private viewService: ViewService,
    private unsplashService: UnsplashService,
    private favoritesService: FavoritesService,
    private exploreService: ExploreService
  ) {
    this.defaultAvatar = environment.defaultAvatar;
    this.defaultMenuImage = environment.defaultMenuImage;

    this.basketService.basketSubject.subscribe(value => {
      this.basket = value;
    });
  }

  ngOnInit(): void {
    this.favoriteClickSubjectSubscription = this.favoritesService.favoriteClickSubject.subscribe(value => {
      if (value) {
        this.openFoodGalleryModal();

        this.favoritesService.favoriteClickSubject.next(false);
      }
    });

    this.searchParams = this.searchParamsLocalStorage.getItem();

    this.exploreService.searchResultSubject.subscribe(searchResult => {
      this.searchResult = searchResult;
    });

    this.unsplashService.randomPhoto().subscribe(image => {
      this.backgroundImage = image;

      this.backgroundImage.url += '&w=720&h=720';
    });

    this.setEditMode();

    setTimeout(() => {
      this.isDataReady = true;
    }, 200);

    this.favoritesService.getFavoritesBySearchParams(this.searchParams)
      .then((locationFavorites: LocationFavorites) => {
        this.locationFavorites = locationFavorites;
      });

    this.phantomFood = new Food();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('menus')) {
      console.log('MENUS: ', this.menus);
      this.setFood();
    }

    if (changes.hasOwnProperty('restaurant')) {
      this.setEditMode();
    }

    if (this.searchResult?.groups !== undefined && this.searchResult.exampleRestaurant) {
      this.favoritesService.getFavoritesBySearchParams(this.searchParams)
        .then((locationFavorites: LocationFavorites) => {
          this.locationFavorites = locationFavorites;
        });
    }
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe(value => {
      if (value['first-menu']) {
        DomUtils.querySelectorAsync('[id^="restaurant-desktop-menu-"]').then(element => {
          setTimeout(() => {
            element.scrollIntoView({behavior: 'auto', block: 'start', inline: 'nearest'});
          }, 100);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.favoriteClickSubjectSubscription.unsubscribe();
  }

  private setEditMode() {
    if (this.restaurant) {
      this.isEditModeLocalStorage = new BooleanLocalStorage(
        LocalStorageKey.IS_EDIT_MODE,
        {
          hostname: this.restaurant.hostname,
          locationId: this.restaurant.locationIndex,
          userId: this.currentUser?.id
        }
      );

      this.isEditMode = this.isEditModeLocalStorage.getItem();
    }
  }

  @HostListener('window:scroll')
  onScroll() {
    const menusElements = document.querySelectorAll('.restaurant-desktop-menu');

    const visibleMenusElements = Array.prototype.filter.call(menusElements, (node: HTMLElement) => {
      const viewportOffset = node.getBoundingClientRect();

      return viewportOffset.bottom > 0 && viewportOffset.top < window.innerHeight;
    });

    visibleMenusElements.forEach((menuElement: HTMLElement) => {
      const menuElementRect = menuElement.getBoundingClientRect();

      if (menuElementRect.top < this.marginFromPreviousSectionsLastFood) {
        const id = menuElement.getAttribute('data-id');
        const menuActive = this.menus?.find(menuI => menuI.id === +id);

        if (menuActive) {
          this.menuActive = menuActive;
        }
      }
    });

    const restaurantDesktopRect = this.restaurantDesktop?.nativeElement.getBoundingClientRect();

    if (restaurantDesktopRect?.top === 0) {
      this.menuActive = null;
    } else if (!this.menuActive) {
      this.menuActive = this.menus[0];
    }
  }

  private setFood() {
    if (!this.restaurantRouteParams) {
      return;
    }

    if (this.restaurantRouteParams.foodWithIdSlug && this.menus?.length > 0) {
      const findFood = categoryI => categoryI.foods.find(foodI => foodI.id === this.restaurantRouteParams.foodId);
      const findCategory = menuI => menuI.categories.find(categoryI => findFood(categoryI) !== undefined);
      const menu = this.menus.find(menuI => findCategory(menuI) !== undefined);

      if (menu !== undefined) {
        const category = findCategory(menu);

        if (category !== undefined) {
          const food = findFood(category);

          if (food !== undefined) {
            this.toView(menu, food, ViewType.CLASSIC, true);
          }
        }
      }
    } else if (this.restaurantRouteParams.menuSlug && this.menus?.length > 0) {
      const menu = this.menus.find(menuI => menuI.slug === this.restaurantRouteParams.menuSlug);

      if (menu) {
        this.selectMenu(menu);
      }
    }
  }

  selectMenu(menu: Menu) {
    this.menuActive = menu;

    setTimeout(() => {
      const menuElement = document.querySelector(
        `#restaurant-desktop-menu-${menu.slug}`
      );

      if (menuElement) {
        menuElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }
    }, 100);
  }

  clickMenu() {
    setTimeout(() => {
      const menuElement = document.querySelector(`#menu-length-0`);

      if (menuElement) {
        menuElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      }
    }, 100);
  }

  toView(menu: Menu, food: Food, newView: ViewType, init = false) {
    if (!init) {
      if (newView === ViewType.CLASSIC) {
        this.navigatorService.changeLocationTo(this.restaurantRouteParams.restaurantPath + food.profileUrl);
      } else {
        this.navigatorService.changeLocationTo(`${this.restaurantRouteParams.restaurantPath}/${menu.slug}`);
      }
    }

    this.viewService.setViewTypeAndScrollToPhantomView(food.view, newView);
  }

  logoClick() {
    this.menuActive = null;

    this.restaurantDesktop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  openRestaurantModal() {
    this.modalService.open(this.restaurantModalRef, ModalType.RESTAURANT_SQUARE);
  }

  addMeal(food: Food, categoryId: number) {
    if (!food.hasSections || food.sectionsOpen) {
      this.basket.addFood(food, categoryId);
      this.basketService.basket = this.basket;

      this.closeModals();
    } else {
      setTimeout(() => {
        this.notificationService.makeSelection();
      }, 100);
    }
  }

  @ModalClose()
  private closeModals() {
  }

  get paddingBottom() {
    const imageContent = document.querySelector('.div-rectangle-container');

    return `${window.innerHeight - imageContent.clientHeight - 181}px`;
  }

  openFoodGalleryModal() {
    this.modalService.open(this.foodGalleryModalRef, ModalType.FAVORITE_SQUARE);
  }
}

<div class="row row-search">
  <ng-container *ngIf="hasRestaurants">
    <div *ngIf="typeCategorySection.isExample" class="col-search col-4 mb-1">
      <app-div-rectangle (click)="installPwa()">
        <ng-template
          [ngTemplateOutlet]="tileTemplate"
          [ngTemplateOutletContext]="{tileType: tileTypeList.SHARE}"
        ></ng-template>
      </app-div-rectangle>
    </div>

    <ng-container *ngFor="let typeCategory of typeCategorySection.typeCategories; let typeCategoryIndex = index;">
      <ng-container *ngFor="let restaurant of typeCategory.restaurants; let restaurantIndex = index;">
        <div *ngIf="restaurant" class="col-search col-4 mb-1">
          <div id="phantom-restaurant-{{restaurant.id}}" class="phantom-block"></div>

          <app-div-rectangle (click)="onClickRestaurant(restaurant)">
            <ng-template
              [ngTemplateOutlet]="tileTemplate"
              [ngTemplateOutletContext]="{
                tileType: tileTypeList.RESTAURANT,
                title: (restaurant.nameView | city:cityName | abbreviate:true:cityName)
              }"
            ></ng-template>
          </app-div-rectangle>
        </div>

        <ng-container *ngIf="typeCategorySection.hasChain">
          <div *ngIf="isChainSingle(typeCategoryIndex, restaurantIndex)" class="col-search col-4 mb-1"></div>
        </ng-container>
      </ng-container>
    </ng-container>

    <div *ngIf="typeCategorySection.isExample" class="col-search col-4 mb-1">
      <app-div-rectangle (click)="onClickAddRestaurant()">
        <ng-template
          [ngTemplateOutlet]="tileTemplate"
          [ngTemplateOutletContext]="{tileType: tileTypeList.ADD_RESTAURANT}"
        ></ng-template>
      </app-div-rectangle>
    </div>
  </ng-container>

  <ng-container *ngIf="!hasRestaurants && typeCategorySection.hasChain">
    <div class="col-search col-4 mb-1"></div>
  </ng-container>
</div>

<ng-template #tileTemplate let-tileType="tileType" let-title="title">
  <div class="card card-restaurant flex-content-vc-hc">
    <span class="restaurant-title title-standard text-white">
      <ng-container *ngIf="tileType === tileTypeList.RESTAURANT">
          {{ title }}
      </ng-container>

      <i *ngIf="tileType === tileTypeList.SHARE" class="icon-v2 download"></i>

      <i *ngIf="tileType === tileTypeList.ADD_RESTAURANT" class="icon-v2 plus-white scale-1_5"></i>
    </span>
  </div>
</ng-template>

<ng-template #restaurantAddModalRef let-c="close">
  <app-restaurant-add-modal
    (restaurantCreated)="restaurantCreated($event)"
  ></app-restaurant-add-modal>
</ng-template>

<ng-template #addToHomeScreenModalRef let-c="close">
  <app-add-to-home-screen-modal
  ></app-add-to-home-screen-modal>
</ng-template>

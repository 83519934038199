import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { CityWithChild } from '../../../domains/city-with-child';
import { CountryWithChild } from '../../../domains/country-with-child';
import { CountyWithChild } from '../../../domains/county-with-child';
import { District } from '../../../domains/district';
import { RegionWithChild } from '../../../domains/region-with-child';
import { StateWithChild } from '../../../domains/state-with-child';
import { LocalStorage } from '../../../local-storage/local-storage';
import { DeviceType } from '../../../models/enums/device-type';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { ModalType } from '../../../models/enums/modal-type';
import { Modal } from '../../../models/modal';
import { Place } from '../../../models/place';
import { SearchParams } from '../../../models/search-params';
import { ModalService } from '../../../services/modal.service';
import { NavigatorService } from '../../../services/navigator.service';
import { SearchCityService } from '../../../services/search-city.service';
import { SearchParamsService } from '../../../services/search-params.service';

@Component({
  selector: 'app-search-location',
  templateUrl: './search-location.component.html',
  styleUrls: ['./search-location.component.scss']
})
export class SearchLocationComponent implements OnInit, OnDestroy {
  @Input() searchParams: SearchParams;
  @Input() country: CountryWithChild;

  @Output() findingMe = new EventEmitter<boolean>();
  @Output() selectRestaurant = new EventEmitter<boolean>();
  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() countySelectedChange = new EventEmitter<CountyWithChild>();
  @Output() regionSelectedChange = new EventEmitter<RegionWithChild>();
  @Output() citySelectedChange = new EventEmitter<CityWithChild>();
  @Output() stateSelectedChange = new EventEmitter<StateWithChild>();
  @Output() districtSelectedChange = new EventEmitter<District>();
  @Output() localitySelectedChange = new EventEmitter<boolean>();

  @ViewChild('searchCityModalRef') searchCityModalRef: ElementRef;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;
  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  searchCityModal: Modal;
  cityModalSubjectSubscription: Subscription;
  inputHover: boolean;
  leftInputHover = false;
  rightInputHover = false;
  leftInputValue = '';
  rightInputValue = '';
  searchParamsLocalStorage = new LocalStorage(SearchParams, LocalStorageKey.SEARCH_PARAMS);

  constructor(
    private modalService: ModalService,
    private searchCityService: SearchCityService,
    private searchParamsService: SearchParamsService,
    private navigatorService: NavigatorService
  ) {
    this.cityModalSubjectSubscription = this.searchCityService.openSearchCityModalSubject.subscribe(() => {
      this.openSearchCityModal();
    });
  }

  ngOnInit(): void {
    if (this.cityModalSubjectSubscription === undefined) {
      this.cityModalSubjectSubscription = this.searchCityService.openSearchCityModalSubject.subscribe(value => {
        this.openSearchCityModal();
      });
    }
  }

  ngOnDestroy(): void {
    this.cityModalSubjectSubscription?.unsubscribe();
  }

  onLeftInputClick(value: any) {
    this.leftInputValue = value;
  }

  onRightInputClick(value: any) {
    this.rightInputValue = value;
  }

  openSearchCityModal() {
    this.searchCityModal = this.modalService.open(
      this.searchCityModalRef,
      this.isMobile ? ModalType.TOP_SEARCH : ModalType.SEARCH_SQUARE
    );
  }

  onSelectPlace(place: Place) {
    this.searchCityModal?.close();

    this.searchParamsService.getByPlaceId(place.placeId).subscribe(searchParams => {
      this.setSearchParamsAndGoToPath(searchParams);
    });
  }

  onFindMe() {
    this.searchCityModal?.close();
    this.findingMe.emit(true);

    this.searchParamsService.getByMyGeoLocation(true).then(searchParamsByGeoLocation => {
      this.setSearchParamsAndGoToPath(searchParamsByGeoLocation);
    });
  }

  private setSearchParamsAndGoToPath(searchParams: SearchParams) {
    this.searchParamsLocalStorage.setItem(searchParams);

    this.navigatorService.goToUrl(searchParams.path).then();
  }

  onSelectRestaurant($event: boolean) {
    this.selectRestaurant.emit($event);
  }
}

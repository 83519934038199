import { Component, Input, OnInit } from '@angular/core';
import { Auth } from 'src/app/decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { User } from '../../../domains/user';
import { CheckoutService } from '../../../services/checkout.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-restaurant-summary',
  templateUrl: 'restaurant-summary.component.html',
  styleUrls: ['restaurant-summary.component.scss']
})
export class RestaurantSummaryComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;

  @CurrentUser() currentUser: User;

  isSubscribed: boolean;

  constructor(
    private checkoutService: CheckoutService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    if (this.currentUser) {
      this.checkoutService.isUserSubscribed(this.currentUser).subscribe(value => {
        this.isSubscribed = value;
      });
    }
  }

  moreClick() {
    this.notificationService.moreInfoNotification();

    this.subscribe();
  }

  @Auth()
  @Subscribe()
  subscribe() {
  }
}

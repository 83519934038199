import { Component, Input, OnInit } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { NGXLogger } from 'ngx-logger';
import { Extra } from '../../../../../domains/extra';
import { Food } from '../../../../../domains/food';
import { Ordering } from '../../../../../domains/ordering';
import { Section } from '../../../../../domains/section';
import { SectionType } from '../../../../../models/enums/section-type';
import { ExtraService } from '../../../../../services/extra.service';
import { SectionService } from '../../../../../services/section.service';

@Component({
  selector: 'app-options-edit',
  templateUrl: './options-edit.component.html',
  styleUrls: ['./options-edit.component.scss']
})
export class OptionsEditComponent implements OnInit {
  @Input() food: Food;
  @Input() section: Section;

  sectionType = SectionType;

  constructor(private sectionService: SectionService, private extraService: ExtraService, private logger: NGXLogger) {
  }

  ngOnInit() {
    const sectionCloned = plainToClass(Section, classToPlain(this.section));
    sectionCloned.extras = [];

    this.sectionService
      .addSection(sectionCloned, this.food)
      .subscribe({
        next: (sectionAdded: Section) => {
          this.section.id = sectionAdded.id;
        }, error: error => {
          this.logger.error('On adding section', error);
        }
      });
  }

  onExtraChange(extra) {
    if (extra.id) {
      this.extraService
        .editExtra(extra)
        .subscribe({
          error: error => {
            this.logger.error('On editing extra', error);
          }
        });
    } else {
      const reordered = this.section.extrasSortAndReorder();

      this.extraService
        .addExtra(extra, this.section)
        .subscribe({
          next: addedExtra => {
            extra.id = addedExtra.id;

            if (reordered) {
              this.changeOrdering();
            }
          }, error: error => {
            this.logger.error('On adding extra', error);
          }
        });
    }
  }

  onPriceChange(event, extra: Extra) {
    extra.price = event.target.value;
    this.onExtraChange(extra);
  }

  sectionChange(value, isMin) {
    if (isMin) {
      this.section.min = value;
    } else {
      this.section.max = value;
    }

    this.sectionEdit();
  }

  sectionEdit() {
    this.sectionService
      .editSection(this.section)
      .subscribe({
        error: error => {
          this.logger.error('On editing section', error);
        }
      });
  }

  private changeOrdering() {
    const extrasOrderings: Ordering[] = [];

    this.section.extras.forEach(extraI => {
      const ordering = new Ordering(extraI.id, extraI.ordering);
      extrasOrderings.push(ordering);
    });

    this.extraService
      .changeOrdering(extrasOrderings)
      .subscribe({
        error: error => {
          this.logger.error('On changing extras orderings', error);
        }
      });
  }
}

<ng-container *ngIf="food.view.isClassic">
  <app-phantom [view]="food.view" [viewType]="viewType.CLASSIC"></app-phantom>

  <div class="pb-1">
    <app-classic-food-image [food]="food" (viewClick)="toView()"></app-classic-food-image>
  </div>

  <app-classic-food-controls
    *ngIf="!food.googleImage"
    [house]="house"
    [restaurant]="restaurant"
    [food]="food"
    (addClick)="addMeal($event)"
  ></app-classic-food-controls>

  <div [ngClass]="{'list-bottom': isLast}"></div>
</ng-container>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { Food } from '../../../domains/food';
import { Restaurant } from '../../../domains/restaurant';
import { DeviceType } from '../../../models/enums/device-type';
import { TagType } from '../../../models/enums/tag-type';
import { Image } from '../../../models/image';

@Component({
  selector: 'app-classic-food-image',
  templateUrl: './classic-food-image.component.html',
  styleUrls: ['./classic-food-image.component.scss']
})
export class ClassicFoodImageComponent implements OnInit {
  @Input() food: Food;
  @Input() house = false;
  @Input() summary = false;
  @Input() restaurant: Restaurant;
  @Input() favModal = false;
  @Input() images: Array<Image> = [];

  @Output() viewClick = new EventEmitter<boolean>();
  @Output() galleryClick = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  tagType = TagType;

  constructor() {
  }

  ngOnInit(): void {
  }

  toView() {
    this.viewClick.emit(true);
  }

  @Auth()
  galleryClicked() {
    this.galleryClick.emit(true);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StripeSession } from '../domains/stripe-session';
import { StripeSubscription } from '../domains/stripe-subscription';
import { User } from '../domains/user';

@Injectable({providedIn: 'root'})
export class CheckoutService {
  private readonly checkoutUrl: string;

  constructor(private http: HttpClient) {
    this.checkoutUrl = `${environment.apiEndpoint}/api/checkout`;
  }

  public getSession(price: number) {
    return this.http.get<StripeSession>(
      `${this.checkoutUrl}/session`,
      {params: {price: price.toString()}}
    );
  }

  public update(price: number) {
    return this.http.get(
      `${this.checkoutUrl}/update`,
      {params: {price: price.toString()}}
    );
  }

  public isSessionPaid(sessionId: string) {
    return this.http.get<boolean>(
      `${this.checkoutUrl}/check-session`,
      {params: {'session-id': sessionId}}
    );
  }

  public getUserSubscriptionInfo(user: User) {
    return this.http.get<StripeSubscription>(`${this.checkoutUrl}/subscription`, {params: {username: user.username}})
      .pipe(map(value => {
        if (value) {
          return plainToClass(StripeSubscription, value);
        }

        return null;
    }));
  }

  public isUserSubscribed(user: User) {
    return this.http.get<boolean>(`${this.checkoutUrl}/is-subscribed`, {params: {username: user.username}});
  }
}

<select
  *ngIf="!isDesktop"
  #selectElement
  appFocus
  [appSelectWidthByText]="widthByText"
  [andTitle]="title"
  [ngClass]="{'w-100': titleWidth, 'opacity-50': disabled, 'd-none': visibilityDelay}"
  [ngStyle]="{
    'paddingLeft': paddingLeftZero ? '0px' : paddingRightZero || !titleWidth ? '' : 'calc((100% - ' + titleWidth +') / 2)',
    'paddingRight': paddingRightZero ? '0px' : paddingLeftZero || !titleWidth ? '' : 'calc((100% - ' + titleWidth +') / 2)'
  }"
  [disabled]="disabled"
  class="picker-select text-center {{pickerClasses}}"
  (change)="optionSelected($event)"
  (click)="onSelectClick($event)"
>
  <option *ngIf="!value" [value]="" selected disabled>{{ title }}</option>

  <ng-container *ngIf="picker?.defaultGroup.options.length > 0">
    <option
      *ngFor="let option of picker.defaultGroup.options"
      [value]="option.value"
      [selected]="value == option.value"
    >
      {{ option.label }}
    </option>
  </ng-container>

  <ng-container *ngIf="picker?.additionalGroups.length > 0">
    <optgroup *ngFor="let group of picker.additionalGroups" label="{{group.label}}">
      <option *ngFor="let option of group.options" [value]="option.value" [selected]="value == option.value">
        {{ option.label }}
      </option>
    </optgroup>
  </ng-container>
</select>

<a
  *ngIf="isDesktop"
  appHrefNone
  class="picker-a {{classes}}"
  (click)="openPickerModal()"
>
  {{ title }}
</a>

<ng-template #pickerModalRef let-c="close">
  <app-picker-modal [picker]="picker" (selected)="linkClicked($event); c('close')"></app-picker-modal>
</ng-template>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../../environments/environment';
import { Auth } from '../../../decorators/auth.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { LocalStorageValue } from '../../../decorators/local-storage-value.decorator';
import { RouteParamsValue } from '../../../decorators/route-params-value.decorator';
import { Menu } from '../../../domains/menu';
import { Restaurant } from '../../../domains/restaurant';
import { User } from '../../../domains/user';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { StringLocalStorage } from '../../../local-storage/string-local-storage';
import { EditRole } from '../../../models/enums/edit-role';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';
import { RouteParamsType } from '../../../models/enums/route-params-type';
import { RestaurantRouteParams } from '../../../models/route-params/restaurant-route-params';
import { MenuService } from '../../../services/menu.service';
import { NavigatorService } from '../../../services/navigator.service';
import { RestaurantService } from '../../../services/restaurant.service';
import { WebSocketService } from '../../../services/web-socket.service';
import { VolunteerIdListener } from '../../../websocket/listeners/volunteer-id-listener';

@Component({
  selector: 'app-menu-router',
  templateUrl: './menu-router.component.html',
  styles: []
})
export class MenuRouterComponent implements OnInit, OnChanges {
  @Input() restaurant: Restaurant;

  @CurrentUser() currentUser: User;

  @RouteParamsValue(RouteParamsType.RESTAURANT) restaurantRouteParams: RestaurantRouteParams;

  @LocalStorageValue(BooleanLocalStorage, LocalStorageKey.IS_EDIT_MODE, {restaurant: true, user: true})
  isEditModeLocalStorage: BooleanLocalStorage;

  @LocalStorageValue(StringLocalStorage, LocalStorageKey.EDIT_ROLE, {restaurant: true, user: true})
  editRoleLocalStorage: StringLocalStorage;

  @LocalStorageValue(BooleanLocalStorage, LocalStorageKey.CROWDSOURCE_HAS_CHANGES, {restaurant: true})
  csHasChangesLocalStorage: BooleanLocalStorage;

  currentUserRestaurant: Restaurant;

  dataLoaded = false;
  menus: Menu[] = [];
  ownedOrOwnerless: boolean;
  isOwned: boolean;
  isOwnerLess: boolean;
  menusQuantity: number;
  isVolunteer: boolean;
  isEditMode: boolean;
  locationId: number;
  exploreBackgroundImage: string;
  hasMenu = false;
  listView = false;

  menu: Menu = null;
  menuSlug: string;

  constructor(
    public navigatorService: NavigatorService,
    private restaurantService: RestaurantService,
    private webSocketService: WebSocketService,
    private menuService: MenuService,
    private logger: NGXLogger,
  ) {
    this.exploreBackgroundImage = environment.favoritesBackgroundImage;
  }

  ngOnInit() {
    this.hasMenu = this.restaurant.menus.filter(value => value.categories.length > 0).length > 0;

    this.menuSlug = this.restaurantRouteParams.menuSlug;

    this.isVolunteer = this.currentUser &&
      (!this.restaurant.hero || this.restaurant.hero.user.id === this.currentUser.id);

    this.isEditMode = this.isEditModeLocalStorage.getItem();

    this.isOwnerLess = !this.restaurant.isOwned;
    this.isOwned = this.restaurant.isOwned;

    if (this.menuSlug) {
      this.menu = this.restaurant.menus.find(menuIterated => menuIterated.slug === this.menuSlug);

      if (
        this.isOwnerLess &&
        this.menu &&
        this.currentUser &&
        (!this.restaurant.hero || (this.isVolunteer && this.isEditMode))
      ) {
        this.menuService.getFromRedisByMenuId(this.menu.id)
          .subscribe({
            next: value => {
              if (value) {
                this.menu = value;
              }
            }, error: error => {
              this.logger.error('On getting from redis by menu id', error);
            }
          });
      }
    }

    this.dataLoaded = true;

    if (this.currentUser) {
      this.restaurantService.getCurrentUserRestaurant().subscribe((restaurant: Restaurant) => {
        this.currentUserRestaurant = restaurant;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('restaurant') && this.restaurant) {
      this.menusQuantity = this.restaurant.menus.length;
      this.isOwnerLess = !this.restaurant.isOwned;
      this.isOwned = this.restaurant.isOwned;
      this.ownedOrOwnerless = this.isOwnerLess || this.isOwned;

      this.isVolunteer = this.currentUser &&
        (!this.restaurant.hero || this.restaurant.hero.user.id === this.currentUser.id);

      this.setEditMode();

      setTimeout(() => {
        this.dataLoaded = true;
      }, 100);

      if (this.ownedOrOwnerless && !this.restaurant.published) {
        this.webSocketService
          .registerListener(new VolunteerIdListener(this.restaurant.hostname))
          .subject
          .subscribe(volunteerId => {
            if (this.currentUser.id !== volunteerId) {
              this.editModeToggle(false);
              window.location.reload();
            }
          });
      }

      if (!this.restaurant.qr) {
        this.restaurantService.getQR().subscribe(image => {
          this.restaurant.qr = image;
        });
      }
    }
  }

  switchToggle(value: boolean) {
    this.editModeToggle(value);
    const hasChanges = this.csHasChangesLocalStorage?.getItem();

    if (!value && hasChanges) {
      this.csHasChangesLocalStorage.setItem(false);
    }
  }

  @Auth()
  buildAsFan() {
    this.editModeToggle(true);
  }

  @Auth()
  goToRestaurantToEditMode() {
    this.isEditModeLocalStorage.setItem(true);
    this.editRoleLocalStorage.setItem(EditRole.FAN);

    this.setEditMode();

    this.navigatorService.goToRestaurant(this.restaurant.hostname);
  }

  private setEditMode() {
    this.isEditMode = this.isEditModeLocalStorage.getItem();
  }

  private editModeToggle(value: boolean) {
    setTimeout(() => {
      this.isEditMode = value;
    }, 200);

    this.isEditModeLocalStorage.setItem(value);
  }

  get isAdminUser(): boolean {
    return this.currentUser?.isAdmin(this.restaurant);
  }

  onSaveChanges() {
    this.switchToggle(false);

    window.location.reload();
  }

  onViewChanged(event) {
    this.listView = event;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { LocationEmbed } from '../../../../domains/location-embed';
import { Restaurant } from '../../../../domains/restaurant';
import { RestaurantEmbed } from '../../../../domains/restaurant-embed';
import { RestaurantSimple } from '../../../../domains/restaurant-simple';
import { DeviceType } from '../../../../models/enums/device-type';
import { EmbedType } from '../../../../models/enums/embed-type';
import { ErrorResponseType } from '../../../../models/enums/error-response-type';
import { LocationType } from '../../../../models/enums/location-type';
import { TourType } from '../../../../models/enums/tour-type';
import { SearchParams } from '../../../../models/search-params';
import { EmbedService } from '../../../../services/embed.service';
import { NotificationService } from '../../../../services/notification.service';

@Component({
  selector: 'app-video-modal-input',
  templateUrl: 'video-modal-input.component.html',
  styleUrls: ['../video-modal.component.scss']
})
export class VideoModalInputComponent implements OnInit {
  @Input() inputPlaceholder: string;
  @Input() textInput: string;
  @Input() searchParams: SearchParams;
  @Input() type: TourType;
  @Input() restaurant: Restaurant | RestaurantSimple;

  @Output() videoAdded = new EventEmitter<RestaurantEmbed | LocationEmbed>();
  @Output() tourTypeChanged = new EventEmitter<TourType>();
  @Output() videoCanceled = new EventEmitter<boolean>();

  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  isUrlValid = false;
  submitClicked = false;
  TourType = TourType;
  currentPost = { caption: '' };

  constructor(
    private userEmbedService: EmbedService,
    private notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
  }

  onSwitcherChange(event) {
    this.type = (event.target as HTMLInputElement).checked ? TourType.WALKING : TourType.DINING;
  }

  validateUrl(event) {
    if (!event.target.value) {
      return false;
    }

    this.isUrlValid = event.target.checkValidity();
  }

  onSubmitClick(url: string) {
    this.submitClicked = true;

    if (this.isUrlValid) {
      let embed: RestaurantEmbed | LocationEmbed;
      let embedType: EmbedType;

      if (this.restaurant) {
        embedType = EmbedType.RESTAURANT;
        embed = new RestaurantEmbed();

        embed.restaurantId = this.restaurant.id;
      } else {
        embedType = EmbedType.LOCATION;
        embed = new LocationEmbed();

        embed.tourType = this.type;

        embed.locationType = this.searchParams.locationType;

        if (embed.locationType === LocationType.CITY) {
          embed.cityId = this.searchParams.locationId;
        } else {
          embed.districtId = this.searchParams.locationId;
        }
      }

      embed.url = url;
      embed.width = '576';
      embed.height = '';

      this.userEmbedService.add(embed, embedType)
        .subscribe({
          next: value => {
            this.videoAdded.emit(value);
          }, error: err => {
            if (err.status === 500) {
              this.notificationService.embedError();
            } else if (err.error.type === ErrorResponseType.AlreadyExistsException) {
              this.notificationService.videoAlreadyExists();
            } else {
              this.notificationService.embedError();
            }
          }
        });
    }

    this.currentPost.caption = '';

    this.submitClicked = false;
  }

  cancelVideo() {
    this.videoCanceled.emit(true);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-desktop-topnav-loader',
  templateUrl: './search-desktop-topnav-loader.component.html',
  styleUrls: [
    '../../../search-desktop/search-desktop.component.scss',
    '../../../../../../vendor/libs/ng-select/ng-select.scss',
    '../../search-desktop-loader/search-desktop-loader.component.scss'
  ]
})
export class SearchDesktopTopnavLoaderComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}

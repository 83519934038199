import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { classToPlain, plainToClass } from 'class-transformer';
import { nanoid } from 'nanoid';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Restaurant } from '../domains/restaurant';
import { RestaurantSimple } from '../domains/restaurant-simple';
import { RestaurantSuggestion } from '../domains/restaurant-suggestion';
import { Token } from '../domains/token';
import { User } from '../domains/user';
import { BooleanLocalStorage } from '../local-storage/boolean-local-storage';
import { LocalStorage } from '../local-storage/local-storage';
import { StringLocalStorage } from '../local-storage/string-local-storage';
import { CookieKey } from '../models/enums/cookie-key';
import { LocalStorageKey } from '../models/enums/local-storage-key';
import { LocationType } from '../models/enums/location-type';
import { MenuType } from '../models/enums/menu-type';
import { Image } from '../models/image';
import { Picker } from '../models/picker';
import { PickerGroup } from '../models/picker-group';
import { RestaurantLocation } from '../models/restaurant-location';
import { RestaurantLocationDetails } from '../models/restaurant-location-details';
import { SearchParams } from '../models/search-params';
import { PostType } from '../models/types/post-type';
import { UnsplashImage } from '../models/unsplash-image';
import { GoogleImagePipe } from '../pipes/google-image.pipe';
import { FoodService } from './food.service';

@Injectable({providedIn: 'root'})
export class RestaurantService {
  private readonly restaurantUrl: string;

  private restaurantSimplesCache$ = new Map<number, Observable<RestaurantSimple>>();
  private restaurantsCache$ = new Map<string, Observable<Restaurant>>();
  private currentUserRestaurantCache$: Observable<Restaurant>;
  private userTopRestaurantCache$: Observable<Restaurant>;
  private userTopRestaurantSimpleCache$: Observable<RestaurantSimple>;
  private currentUserRestaurantsCache$: Observable<Array<Restaurant>>;
  private currentUserRestaurantSimplesCache$: Observable<Array<RestaurantSimple>>;
  private restaurantMenusCache$ = new Map<number, Observable<Array<PickerGroup>>>();
  private googleBackgroundImageCache$ = new Map<number, Observable<string>>();

  private currentUserRestaurantSubject: { [locationId: number]: Subject<Restaurant> } = {};

  readonly foodGalleryTypes = {
    inside: PostType.PLACE_INSIDE,
    outside: PostType.PLACE_OUTSIDE,
  };

  restaurantHashInitialLocalStorage: StringLocalStorage;
  restaurantHashCurrentLocalStorage: StringLocalStorage;

  constructor(private http: HttpClient, private foodService: FoodService) {
    this.restaurantUrl = `${environment.apiEndpoint}/api/restaurants`;
  }

  private getCurrentRestaurantCommon(hostname: string, locationId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!hostname) {
        resolve(null);
      }

      this.setRestaurantHashLocalStorages(hostname, locationId);

      const restaurantHashInitial = this.restaurantHashInitialLocalStorage.getItem();
      const restaurantHashCurrent = this.restaurantHashCurrentLocalStorage.getItem();

      const params = {};

      if (restaurantHashInitial && restaurantHashCurrent && restaurantHashInitial !== restaurantHashCurrent) {
        params['hash'] = restaurantHashCurrent;
      }

      const userLocalStorage = new LocalStorage(User, CookieKey.USER);
      const user = userLocalStorage.getItem();

      if (user) {
        const isEditModeLocalStorage = new BooleanLocalStorage(
          LocalStorageKey.IS_EDIT_MODE,
          {hostname, locationId, userId: user.id}
        );

        if (isEditModeLocalStorage.getItem()) {
          params['edit'] = nanoid(5);
        }
      }

      this.http.get<Restaurant>(`${this.restaurantUrl}/current`, {params, observe: 'response'})
        .subscribe({
          next: response => {
            const restaurantHashNew = response.headers.get('RestaurantHash');

            if (!restaurantHashInitial) {
              this.restaurantHashInitialLocalStorage.setItem(restaurantHashNew);
            }

            this.restaurantHashCurrentLocalStorage.setItem(restaurantHashNew);

            const restaurantObject = response.body;
            const restaurant = plainToClass(Restaurant, restaurantObject);

            restaurant.periodsGroupAndSort();

            resolve(restaurant);
          }, error: error => {
            reject(error);
          }
        });
    });
  }

  public getCurrentRestaurant(hostname: string, locationId = 1): Subject<Restaurant> {
    if (!locationId) {
      locationId = 1;
    }

    if (!this.currentUserRestaurantSubject.hasOwnProperty(locationId)) {
      this.currentUserRestaurantSubject[locationId] = new Subject<Restaurant>();
    }

    this.getCurrentRestaurantCommon(hostname, locationId).then(restaurant => {
      this.currentUserRestaurantSubject[locationId].next(restaurant);

      this.restaurantHashChanged(hostname, locationId).then(restaurantHashChanged => {
        if (restaurantHashChanged) {
          this.getCurrentRestaurantCommon(hostname, locationId).then(restaurantChanged => {
            this.currentUserRestaurantSubject[locationId].next(restaurantChanged);
          });
        }
      });
    }, error => {
      this.currentUserRestaurantSubject[locationId].error(error);
    });

    return this.currentUserRestaurantSubject[locationId];
  }

  public getUserTopRestaurant() {
    if (this.userTopRestaurantCache$) {
      return this.userTopRestaurantCache$;
    }

    const userTopRestaurant$ = this.http.get<Restaurant>(`${this.restaurantUrl}/user-top`)
      .pipe(
        switchMap((restaurant: Restaurant) => of(plainToClass(Restaurant, restaurant))),
        shareReplay(1)
      );

    this.userTopRestaurantCache$ = userTopRestaurant$;

    return userTopRestaurant$;
  }

  public getUserTopRestaurantSimple() {
    if (this.userTopRestaurantSimpleCache$) {
      return this.userTopRestaurantSimpleCache$;
    }

    const userTopRestaurantSimple$ = this.http.get<RestaurantSimple>(`${this.restaurantUrl}/user-top-simple`)
      .pipe(
        switchMap((restaurantSimple: RestaurantSimple) => of(plainToClass(RestaurantSimple, restaurantSimple))),
        shareReplay(1)
      );

    this.userTopRestaurantSimpleCache$ = userTopRestaurantSimple$;

    return userTopRestaurantSimple$;
  }

  public getCurrentUserRestaurant() {
    if (this.currentUserRestaurantCache$) {
      return this.currentUserRestaurantCache$;
    }

    const restaurantObservable = this.http
      .get<Restaurant>(`${this.restaurantUrl}/user`)
      .pipe(
        switchMap((restaurant: Restaurant) => of(plainToClass(Restaurant, restaurant))),
        shareReplay(1)
      );

    this.currentUserRestaurantCache$ = restaurantObservable;

    return restaurantObservable;
  }

  public getCurrentUserRestaurants() {
    if (this.currentUserRestaurantsCache$) {
      return this.currentUserRestaurantsCache$;
    }

    const userRestaurants$ = this.http
      .get<Restaurant[]>(`${this.restaurantUrl}/user/all`)
      .pipe(
        switchMap((restaurants: Restaurant[]) => {
          const restaurants$ = restaurants.map(restaurantPlain => {
            const restaurant = plainToClass(Restaurant, restaurantPlain);
            restaurant.periodsGroupAndSort();

            return restaurant;
          });

          return of(restaurants$);
        }),
        shareReplay(1)
      );

    this.currentUserRestaurantsCache$ = userRestaurants$;

    return userRestaurants$;
  }

  public getCurrentUserRestaurantSimples() {
    if (this.currentUserRestaurantSimplesCache$) {
      return this.currentUserRestaurantSimplesCache$;
    }

    const newRestaurantSimples$ = this.http
      .get<Restaurant[]>(`${this.restaurantUrl}/user/all`, {params: {simple: true}})
      .pipe(
        switchMap((restaurantSimples: RestaurantSimple[]) => of(plainToClass(RestaurantSimple, restaurantSimples))),
        shareReplay(1)
      );

    this.currentUserRestaurantSimplesCache$ = newRestaurantSimples$;

    return newRestaurantSimples$;
  }

  public add(restaurant: Restaurant, searchParams: SearchParams) {
    const restaurantObject = classToPlain(restaurant);

    return this.http.post<Restaurant>(
      `${this.restaurantUrl}`,
      restaurantObject,
      {params: {'search-params': JSON.stringify(searchParams)}}
    );
  }

  public addToReview(restaurant: Restaurant) {
    return this.http.post<Restaurant>(`${this.restaurantUrl}/review`, restaurant);
  }

  public edit(restaurant: Restaurant) {
    this.clearAllCaches();
    restaurant.topFoods = null;

    const restaurantObject = classToPlain(restaurant);

    return this.http.put<Restaurant>(`${this.restaurantUrl}`, restaurantObject)
      .pipe(map(value => plainToClass(Restaurant, value)));
  }

  public delete(restaurant: Restaurant) {
    this.clearAllCaches();

    return this.http.get(`${this.restaurantUrl}/${restaurant.id}`);
  }

  public uploadBackgroundImage(image: Image, type: string) {
    this.clearAllCaches();

    return this.http.put<Image>(`${this.restaurantUrl}/image/${type}`, image)
      .pipe(map(value => plainToClass(Image, value)));
  }

  public deleteBackgroundImage(type: string) {
    this.clearAllCaches();

    return this.http.delete(`${this.restaurantUrl}/image/${type}`);
  }

  getRecent(): Promise<RestaurantSimple[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<RestaurantSimple[]>(`${this.restaurantUrl}/recent`)
        .subscribe({
          next: restaurants => {
            const recentRestaurants = restaurants.map(restaurant =>
              plainToClass(RestaurantSimple, restaurant)
            );

            resolve(recentRestaurants);
          }, error: error => {
            reject(error);
          }
        });
    });
  }

  getRecentPicker(): Promise<Picker> {
    return new Promise((resolve, reject) => {
      this.getRecent().then(recentRestaurants => {
        const picker = new Picker();

        recentRestaurants.forEach(recentRestaurant => {
          picker.addDefaultOption(recentRestaurant.hostname, recentRestaurant.secondNameView);
        });

        resolve(picker);
      }).catch(error => reject(error));
    });
  }

  getCsRestaurants() {
    return this.http
      .get<Array<RestaurantSimple>>(`${this.restaurantUrl}/crowdsourced`)
      .pipe(map(value => plainToClass(RestaurantSimple, value)));
  }

  hasCsRestaurants() {
    return this.http.get<boolean>(`${this.restaurantUrl}/has-crowdsourced`);
  }

  verifyOwnershipEmail(hostname: string) {
    return this.http.put<any>(
      `${this.restaurantUrl}/verify-ownership-email`,
      null,
      {headers: {Restaurant: hostname}}
    );
  }

  verifyOwnership() {
    return this.http.put<any>(`${this.restaurantUrl}/verify-ownership`, null);
  }

  verificationTokenCheck(token: Token) {
    return this.http.put(`${this.restaurantUrl}/verification-token-check`, token);
  }

  getQR() {
    return this.http.get(`${this.restaurantUrl}/get-qr`)
      .pipe(map((response) => {
        return plainToClass(Image, response);
      }));
  }

  downloadQRPdf(restaurant: Restaurant) {
    this.http.get(`${this.restaurantUrl}/get-qr-pdf`, {responseType: 'blob'})
      .subscribe((response: BlobPart) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const link = document.createElement('a');

        if (restaurant.locationIndex === 1) {
          link.download = `${restaurant.hostname}_qr.pdf`;
        } else {
          link.download = `${restaurant.hostname}_${restaurant.locationIndex}_qr.pdf`;
        }

        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      });
  }

  restaurantHashChanged(hostname: string, locationId: number): Promise<boolean> {
    this.setRestaurantHashLocalStorages(hostname, locationId);

    return new Promise((resolve) => {
      const restaurantHash = this.restaurantHashCurrentLocalStorage.getItem();

      this.http
        .get(
          `${this.restaurantUrl}/restaurant-hash-check`,
          {params: {'restaurant-hash': restaurantHash}, responseType: 'text'}
        )
        .subscribe(restaurantHashNew => {
          if (restaurantHash !== restaurantHashNew) {
            this.restaurantHashCurrentLocalStorage.setItem(restaurantHashNew);

            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  private setRestaurantHashLocalStorages(hostname: string, locationId: number) {
    this.restaurantHashInitialLocalStorage = new StringLocalStorage(
      LocalStorageKey.RESTAURANT_HASH_INITIAL,
      {hostname, locationId}
    );

    this.restaurantHashCurrentLocalStorage = new StringLocalStorage(
      LocalStorageKey.RESTAURANT_HASH_CURRENT,
      {hostname, locationId}
    );
  }

  getLocations() {
    return this.http.get<RestaurantSimple[]>(`${this.restaurantUrl}/locations`)
      .pipe(map(value => plainToClass(RestaurantSimple, value)));
  }

  clone(location: RestaurantLocationDetails) {
    return this.http
      .put<Restaurant>(`${this.restaurantUrl}/location`, location)
      .pipe(map(value => plainToClass(RestaurantSimple, value)));
  }

  remove() {
    return this.http.delete<Restaurant>(`${this.restaurantUrl}/location`);
  }

  public getRestaurant(hostname: string, locationId: number): Observable<Restaurant> {
    if (!locationId) {
      locationId = 1;
    }

    const key = `${hostname}_${locationId}`;
    const cachedRestaurant$ = this.restaurantsCache$.get(key);

    if (cachedRestaurant$) {
      return cachedRestaurant$;
    }

    const newRestaurant$ = this.http.get<Restaurant>(`${this.restaurantUrl}/restaurant/${hostname}/${locationId}`)
      .pipe(
        switchMap((restaurantPlain: Restaurant) => {
          const restaurant = plainToClass(Restaurant, restaurantPlain);

          restaurant.menus.forEach(menu => {
            menu.categories.forEach(category => {
              category.foods.forEach(food => {
                this.foodService.setFoodIntoCache(food);
              });
            });
          });

          restaurant.periodsGroupAndSort();

          return of(restaurant);
        }),
        shareReplay(1)
      );

    this.restaurantsCache$.set(key, newRestaurant$);

    return newRestaurant$;
  }

  getRestaurantSimple(id: number): Observable<RestaurantSimple> {
    const cachedRestaurantSimple$ = this.restaurantSimplesCache$.get(id);

    if (cachedRestaurantSimple$) {
      return cachedRestaurantSimple$;
    }

    const newRestaurantSimple$ = this.http.get<RestaurantSimple>(`${this.restaurantUrl}/restaurant/${id}`)
      .pipe(
        switchMap((restaurantSimple: RestaurantSimple) => of(plainToClass(RestaurantSimple, restaurantSimple))),
        shareReplay(1)
      );

    this.restaurantSimplesCache$.set(id, newRestaurantSimple$);

    return newRestaurantSimple$;
  }

  setEnabledMenuTypes(enabledMenus: MenuType[]) {
    return this.http
      .put<Restaurant>(`${this.restaurantUrl}/enabled-menu-types`, enabledMenus);
  }

  getRestaurantMenus(id: number): Observable<Array<PickerGroup>> {
    let cachedRestaurantMenus$ = this.restaurantMenusCache$.get(id);

    if (cachedRestaurantMenus$) {
      return cachedRestaurantMenus$;
    }

    cachedRestaurantMenus$ = this.http.get<Array<PickerGroup>>(`${this.restaurantUrl}/${id}/menus`)
      .pipe(
        switchMap(restaurantMenus => of(plainToClass(PickerGroup, restaurantMenus))),
        shareReplay(1)
      );

    this.restaurantMenusCache$.set(id, cachedRestaurantMenus$);

    return cachedRestaurantMenus$;
  }

  getGoogleBackgroundImage(id: number) {
    let googleBackgroundImage$ = this.googleBackgroundImageCache$.get(id);

    if (googleBackgroundImage$) {
      return googleBackgroundImage$;
    }

    googleBackgroundImage$ = this.http.get<UnsplashImage>(`${this.restaurantUrl}/background-image/${id}`)
      .pipe(
        switchMap(image => {
          if (image) {
            return of(GoogleImagePipe.prototype.transform(image.url, 720));
          }

          return of(null);
        }),
        shareReplay(1)
      );

    this.googleBackgroundImageCache$.set(id, googleBackgroundImage$);

    return googleBackgroundImage$;
  }

  public addSuggestion(restaurantSuggestion: RestaurantSuggestion) {
    return this.http.post<RestaurantSuggestion>(`${this.restaurantUrl}/suggestion`, restaurantSuggestion);
  }

  public getSuggestion(restaurant: RestaurantSimple) {
    return this.http.get<RestaurantSuggestion>(`${this.restaurantUrl}/suggestion/${restaurant.id}`);
  }

  public clearAllCaches() {
    this.restaurantSimplesCache$ = new Map<number, Observable<RestaurantSimple>>();
    this.restaurantsCache$ = new Map<string, Observable<Restaurant>>();
    this.currentUserRestaurantCache$ = null;
    this.userTopRestaurantCache$ = null;
    this.currentUserRestaurantsCache$ = null;
    this.currentUserRestaurantSimplesCache$ = null;
    this.restaurantMenusCache$ = new Map<number, Observable<Array<PickerGroup>>>();
    this.googleBackgroundImageCache$ = new Map<number, Observable<string>>();
  }

  public search(locationType: LocationType, locationId: number, query: string) {
    return this.http.get<RestaurantLocation[]>(`${this.restaurantUrl}/search/${locationType}/${locationId}/${query}`)
        .pipe(map(value => plainToClass(RestaurantLocation, value)));
  }
}

import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { IsDevice } from '../decorators/is-device.decorator';
import { Food } from '../domains/food';
import { Post } from '../domains/post';
import { RestaurantSimple } from '../domains/restaurant-simple';
import { DeviceType } from '../models/enums/device-type';
import { Paths } from '../models/enums/paths';
import { Image } from '../models/image';
import { RestaurantRouteParams } from '../models/route-params/restaurant-route-params';
import { AuthenticationService } from './authentication.service';
import { GalleryService } from './gallery.service';
import { LoaderService } from './loader.service';
import { PostDataService } from './post-data.service';
import { RouterService } from './router.service';

@Injectable({providedIn: 'root'})
export class NavigatorService {
  @IsDevice(DeviceType.DESKTOP) isDesktop: boolean;

  constructor(
    private router: Router,
    private zone: NgZone,
    private authenticationService: AuthenticationService,
    private _location: Location,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private postDataService: PostDataService,
    private loaderService: LoaderService,
    private galleryService: GalleryService
  ) {
  }

  private openUrl(url: string) {
    if (this.isDesktop) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  goToUrl(url: string, queryParams = {}) {
    return this.zone.run(() => {
      return this.router.navigate([url], {queryParams}).then();
    });
  }

  goToUrlWithReload(url: string, queryParams = {}) {
    this.zone.run(() => {
      this.router.navigate([url], {queryParams}).then(() => {
        window.location.reload();
      });
    });
  }

  goToGlobalPath(path: Paths) {
    this.zone.run(() => {
      this.router.navigateByUrl(`/${path}`).then();
    });
  }

  goToHome() {
    this.zone.run(() => {
      this.router.navigateByUrl('/').then();
    });
  }

  goToExploreWithQueryParams(queryParams) {
    const params: NavigationExtras = {};
    params.queryParams = queryParams;

    this.zone.run(() => {
      this.router.navigate(['/'], params).then();
    });
  }

  goToRestaurant(restaurantHost: string, queryParams = {}) {
    const params: NavigationExtras = {};

    if (queryParams) {
      params.queryParams = queryParams;
    }

    this.zone.run(() => {
      this.router.navigate([`/${restaurantHost}`], params).then();
    });
  }

  goToRestaurantWithObject(restaurant: RestaurantSimple, newTab = false) {
    const url = `/${restaurant.hostname}${restaurant.locationIndex !== 1 ? ('/' + restaurant.locationIndex) : ''}`;

    if (newTab) {
      window.open(url, '_blank');
    } else {
      this.zone.run(() => {
        this.router.navigate([url]).then();
      });
    }
  }

  goToRestaurantFromSubPages(fromAdmin = false, timeout = 0) {
    const url = this.router.url.split('?')[0];
    const segments = url.split('/');

    let index = 1;

    if (fromAdmin) {
      index += 1;
    }

    let restaurantUrl = '/' + segments[index];

    if (segments.length > 1 && !isNaN(+segments[index + 1])) {
      restaurantUrl += '/' + segments[index + 1];
    }

    setTimeout(() => {
      this.zone.run(() => {
        this.router.navigateByUrl(restaurantUrl).then();
      });
    }, timeout);
  }

  goToRestaurantFood(restaurant: RestaurantSimple, food: Food, newTab = false) {
    const url = `/${restaurant.hostname}${restaurant.locationIndex !== 1 ? ('/' + restaurant.locationIndex) : ''}${food.profileUrl}`;

    if (newTab) {
      window.open(url, '_blank');
    } else {
      this.zone.run(() => {
        this.router.navigateByUrl(url).then();
      });
    }
  }

  goToRestaurantMenu(restaurant: RestaurantSimple, menuSlug: string) {
    const url = `/${restaurant.hostname}${restaurant.locationIndex !== 1 ? ('/' + restaurant.locationIndex) : ''}/${menuSlug}`;

    this.zone.run(() => {
      this.router.navigateByUrl(url).then();
    });
  }

  goToMenuFromFood(menuSlug: string, timeout: number = 0) {
    let url = this.router.url.split('?')[0];

    setTimeout(() => {
      url += `/${menuSlug}`;

      this.zone.run(() => {
        this.router.navigateByUrl(url).then();
      });
    }, timeout);
  }

  goToFoodGallery(
    restaurantParams: RestaurantRouteParams,
    food: Food,
    post: Post,
    timeout: number = 0
  ) {
    this.loaderService.showLoader();

    this.galleryService.food = food;

    if (post) {
      this.galleryService.post = post;
    }

    setTimeout(() => {
      this.zone.run(() => {
        this.router.navigateByUrl(`${restaurantParams.restaurantPath}${food.profileUrl}/photos`).then();
      });
    }, timeout);

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 500);
  }

  goToFoodGalleryNew(foodImageCsUrl, image: Image, food: Food, timeout: number = 0) {
    this.postDataService.image = image;
    this.postDataService.food = food;

    setTimeout(() => {
      this.zone.run(() => {
        this.router.navigateByUrl(`/${foodImageCsUrl}/new`).then();
      });
    }, timeout);
  }

  goToRestaurantFoodNew(hostname: string, image: Image) {
    this.postDataService.image = image;

    this.zone.run(() => {
      this.router.navigateByUrl(
        `/${hostname}/food-new`
      ).then();
    });
  }

  goToLogin(returnUrl: string) {
    this.zone.run(() => {
      this.router.navigate(['/login'], {queryParams: {returnUrl}}).then();
    });
  }

  goToAdmin(hostname = null, locationIndex = null) {
    if (!hostname) {
      hostname = this.routerService.restaurantHostname;
    }

    this.zone.run(() => {
      this.router.navigate([`/admin/${hostname}${locationIndex ? ('/' + locationIndex) : ''}`]).then();
    });
  }

  goToAdminSettings(locationIndex = null) {
    const hostname = this.routerService.restaurantHostname;

    this.zone.run(() => {
      this.router.navigate(
        [`/admin/${hostname}${locationIndex ? ('/' + locationIndex) : ''}/locations`]
      ).then();
    });
  }

  goToMenuTable(locationIndex = null, menuId = null) {
    const hostname = this.routerService.restaurantHostname;
    let fragment = {};

    if (menuId) {
      const hash = `${menuId}`;

      fragment = {fragment: hash};
    }

    this.zone.run(() => {
      this.router.navigate(
        [`/admin/${hostname}${locationIndex ? ('/' + locationIndex) : ''}/menu-table`],
        fragment
      ).then();
    });
  }

  goToExternal(url: string) {
    this.zone.run(() => {
      location.href = url;
    });
  }

  openInNewWindowInstagram(username: string) {
    window.open(`https://instagram.com/${username}`, '_blank');
  }

  goToSettings(fromUser = false) {
    const params = fromUser ? {fragment: 'user'} : {};

    this.zone.run(() => {
      this.router.navigate([`/settings`], params).then();
    });
  }

  goToProfileSettings(fromSettings = false) {
    const params = fromSettings ? {fragment: 'settings'} : {};

    this.zone.run(() => {
      this.router.navigate([`/settings/profile`], params).then();
    });
  }

  goToSettingsPage(page: string = '') {
    let url = `/settings`;

    if (page) {
      url += `/${page}`;
    }

    this.zone.run(() => {
      this.router.navigate([url]).then();
    });
  }

  goToUser(username: string = '') {
    const url = `/${username ? username : this.username}`;

    this.zone.run(() => {
      this.router.navigate([url]).then();
    });
  }

  goToUserFromPost(post: Post) {
    this.zone.run(() => {
      this.router.navigate([`/${this.username}`], {state: {post}}).then();
    });
  }

  changeLocationTo(url: string, query?: string, state?: string) {
    this._location.go(url, query, state);
  }

  goToUserPostNew(image: Image) {
    this.postDataService.image = image;
    this.postDataService.food = undefined;

    this.router.navigateByUrl(`/${this.username}/post`).then();
  }

  goToUserPost(username: string, post: Post, posts: Post[], page: number = 0, hasNext: boolean = false) {
    this.zone.run(() => {
      this.router.navigateByUrl(`/${username}/post/${post.hash}`, {state: {posts, page, hasNext}}).then();
    });
  }

  goToFavorites() {
    this.zone.run(() => {
      this.router.navigate([`/favorites`]).then();
    });
  }

  goToGems() {
    window.location.href = 'https://evanescent-stilton-9ac.notion.site/mmmm-menu-7b59f1de127140d1bdd53961416183d0';
    // this.zone.run(() => {
    //     this.router.navigate([`/gems`]).then();
    // });
  }

  goToMaps(query: string) {
    const url = `https://www.google.com/maps/search/${encodeURIComponent(query)}`;

    if (this.isDesktop) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  goToMapsRestaurant(restaurant: RestaurantSimple) {
    this.goToMaps(`${restaurant.nameView}, ${restaurant.address}, ${restaurant.zipCode}`);
  }

  goToGoogleImagesSearch(restaurant: RestaurantSimple) {
    const query = encodeURIComponent(restaurant.name + ', ' + restaurant.localityDisplay);

    this.openUrl(`https://google.com/search?q=${query}&site=imghp&tbm=isch`);
  }

  goToGoogleImages(restaurant: RestaurantSimple) {
    if (!restaurant.googlePhotosLink) {
      this.goToMapsRestaurant(restaurant);
    } else {
      this.openUrl(restaurant.googlePhotosLink);
    }
  }

  removeQueryParam(paramToRemove: string) {
    const queryParams = {...this.activatedRoute.snapshot.queryParams};

    if (queryParams[paramToRemove]) {
      delete queryParams[paramToRemove];

      const navigationExtras: NavigationExtras = {
        relativeTo: this.activatedRoute,
        queryParams,
        replaceUrl: true
      };

      this.router.navigate([], navigationExtras).then();
    }
  }

  private get username() {
    return this.authenticationService.currentUser.username;
  }
}

<app-navbar-top-profile *appIfIsMobile [title]="'My restaurant'"></app-navbar-top-profile>

<div
  *ngIf="dataLoaded && isDesktop"
  class="container-fluid container-width pl-0 mt-4 overflow-scroll"
>
  <div class="position-fixed-0 backdrop-filter-brightness-0-5"></div>

  <div class="row">
    <div class="col-8">
      <div class="d-flex justify-content-between">
        <div class="my-3 font-weight-500 text-white">Desktop background</div>

        <div class="my-3 font-weight-500 font-size-0-96rem text-white">{{ restaurant.addressStreet }}</div>
      </div>

      <div class="image cursor-pointer">
        <div class="d-flex flex-wrap">
          <div *ngIf="locations.length > 0" class="ml-auto mb-2">
            <select
              [(ngModel)]="activeLocationId"
              class="select-locations custom-select custom-select-no-border text-right"
              (ngModelChange)="goToAdmin($event)"
            >
              <option disabled [value]="locationId">
                {{ restaurant.address }}
              </option>

              <option *ngFor="let location of locations" [value]="location.locationIndex">
                {{ location.address }}
              </option>
            </select>
          </div>
        </div>

        <div class="image-preview background position-relative">
          <app-image-picker
            (save)="setBackgroundImage($event)"
            (mouseover)="desktopLogoHover = true"
            (mouseleave)="desktopLogoHover = false"
          >
            <div
              *ngIf="desktopLogoHover"
              class="position-absolute-0 flex-content-vc-hc backdrop-filter-brightness-0-5 grayscale"
            >
              <i class="icon image-camera-white scale-6"></i>
            </div>

            <app-restaurant-img
              [images]="restaurant.backgroundImages"
              [alt]="restaurant.secondNameView"
              [elementWidth]="elementWidth"
              [elementHeight]="elementHeight"
            ></app-restaurant-img>
          </app-image-picker>
        </div>

        <div class="my-3 text-white font-weight-500 font-size-0-75rem">
          Feature people in moments instead of an empty restaurant
        </div>
      </div>
    </div>

    <div class="col-4">
      <div class="d-flex justify-content-between">
        <div class="my-3 font-weight-500 text-white">Mobile</div>

        <div class="mb-3 mt-1-4rem font-weight-500 text-white font-size-0-75rem">Optional</div>
      </div>

      <div class="image cursor-pointer">
        <div class="image-preview background position-relative">
          <app-image-picker
            (save)="setMobileBackgroundImage($event)"
            (mouseover)="mobileLogoHover = true"
            (mouseleave)="mobileLogoHover = false"
          >
            <div
              *ngIf="mobileLogoHover"
              class="position-absolute-0 flex-content-vc-hc backdrop-filter-brightness-0-5 grayscale"
            >
              <i class="icon image-camera-white scale-3"></i>
            </div>

            <app-restaurant-img
              [images]="restaurant.mobileBackgroundImages"
              [elementWidth]="elementHeight"
              [elementHeight]="elementHeight"
              [alt]="restaurant.secondNameView"
            ></app-restaurant-img>
          </app-image-picker>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="image mt-4 col-4 px-0-85rem mb-1-7rem cursor-pointer">
      <div class="d-flex justify-content-between">
        <div class="my-3 text-white font-weight-500">White logo</div>

        <div class="my-3 mt-1-3rem text-white font-weight-500 font-size-0-75rem">Optional color accent</div>
      </div>

      <div class="flex-content-hc">
        <div class="image-container image-container-logo">
          <app-div-rectangle>
            <div class="card card-item-image dark-bg">
              <app-image-picker
                accept="png"
                (save)="setAvatar($event)"
                (mouseover)="whiteLogoHovered = true"
                (mouseleave)="whiteLogoHovered = false"
              >
                <div
                  *ngIf="whiteLogoHovered"
                  class="position-absolute-0 backdrop-filter-brightness-0-5 flex-content-vc-hc"
                >
                  <i class="icon image-camera-white scale-4"></i>
                </div>

                <div
                  *ngIf="!restaurant.details.avatar && !whiteLogoHovered"
                  class="image-placeholder flex-content-vc-hc"
                >
                  <i class="icon example-white scale-1_2"></i>
                </div>

                <div *ngIf="restaurant.details.avatar" class="image-preview mx-5">
                  <img
                    appBlurImg
                    [path]="restaurant.details.avatar.path"
                    [options]="{ w: restaurant.details.avatar.width, h: restaurant.details.avatar.height }"
                    [alt]="restaurant.secondNameView"
                    (error)="whiteLogoError()"
                  />
                </div>
              </app-image-picker>
            </div>
          </app-div-rectangle>
        </div>
      </div>

      <div class="image-description mt-3">
        <span class="text-white font-weight-500 font-size-0-75rem">
          All logos require transparent .png format
        </span>
      </div>
    </div>

    <div class="image mt-4 col-4 px-0-85rem mb-1-7rem cursor-pointer">
      <div class="d-flex justify-content-between">
        <div class="w-100 my-3 text-white font-weight-500">Dark monotone</div>
      </div>

      <div class="flex-content-vc-hc">
        <div class="image-container image-container-logo">
          <app-div-rectangle>
            <div class="card card-item-image">
              <app-image-picker
                accept="png"
                (save)="setLogoDark($event)"
                (mouseover)="darkLogoHover = true"
                (mouseleave)="darkLogoHover = false"
              >
                <div
                  *ngIf="darkLogoHover"
                  class="position-absolute-0 backdrop-filter-brightness-0-5 flex-content-vc-hc"
                >
                  <i class="icon image-camera-white scale-4"></i>
                </div>

                <div
                  *ngIf="!restaurant.details.logoDark && !darkLogoHover"
                  class="image-placeholder flex-content-vc-hc"
                >
                  <i class="icon example-dark scale-1_6"></i>
                </div>

                <div *ngIf="restaurant.details.logoDark" class="image-preview mx-5">
                  <img
                    appBlurImg
                    [path]="restaurant.details.logoDark.path"
                    [options]="{ w: restaurant.details.logoDark.width, h: restaurant.details.logoDark.height }"
                    [alt]="restaurant.secondNameView"
                    (error)="darkLogoError()"
                  />
                </div>
              </app-image-picker>
            </div>
          </app-div-rectangle>
        </div>
      </div>
    </div>

    <div class="image mt-4 col-4 px-0-85rem mb-1-7rem cursor-pointer">
      <div class="d-flex justify-content-between">
        <div class="w-100 my-3 text-white font-weight-500">Color</div>
      </div>

      <div class="flex-content-vc-hc">
        <div class="image-container image-container-logo-dark border-radius-0-125rem">
          <app-div-rectangle>
            <div class="card card-item-image">
              <app-image-picker
                accept="png"
                (save)="setLogo($event)"
                (mouseover)="coloredLogoHover = true"
                (mouseleave)="coloredLogoHover = false"
              >
                <div
                  *ngIf="coloredLogoHover"
                  class="position-absolute-0 backdrop-filter-brightness-0-5 flex-content-vc-hc"
                >
                  <i class="icon image-camera-white scale-4"></i>
                </div>

                <div
                  *ngIf="!restaurant.details.logo && !coloredLogoHover"
                  class="image-placeholder flex-content-vc-hc"
                >
                  <i class="icon example-colored scale-1_6"></i>
                </div>

                <div *ngIf="restaurant.details.logo" class="image-preview mx-5">
                  <img
                    appBlurImg
                    [path]="restaurant.details.logo.path"
                    [options]="{
                      w: restaurant.details.logo.width,
                      h: restaurant.details.logo.height
                    }"
                    [alt]="restaurant.secondNameView"
                    (error)="colorLogoError()"
                  />
                </div>
              </app-image-picker>
            </div>
          </app-div-rectangle>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="restaurant" class="w-100 my-3">
    <div class="card my-3 font-weight-500 font-size-0-96rem text-white bg-transparent">
      <div class="text-white">{{ restaurant.addressStreet }}</div>
    </div>

    <div
      class="card mt-3 mb-2rem bg-dark backdrop-filter-blur-10 cursor-pointer"
      (click)="openEditRestaurantModal()"
    >
      <div class="card-body row px-0 mx-3-5rem">
        <ul class="about-restaurant pl-0 mb-0 list-unstyled col-4 text-white">
          <li>
            <span>{{ restaurant.name }}</span>
          </li>

          <li>
            <span>{{ restaurant.secondName }}</span>
          </li>

          <li>
            <span>{{ restaurant.typeCategory.display }}</span>
          </li>
        </ul>

        <ul class="about-restaurant pl-0 mb-0 list-unstyled col-4 text-white">
          <li>
            <div class="flex-content-vc">
              <div class="ml-2">
                <span>{{ restaurant.mmmm }}</span>
              </div>
            </div>
          </li>

          <li>
            <span>{{ restaurant.website }}</span>
          </li>

          <li>
            <span>{{ restaurant.email }}</span>
          </li>

          <li>
            <span>{{ restaurant.nationalPhoneNumber | mask: '(000) 000-0000' }}</span>
          </li>
        </ul>

        <ul class="about-restaurant pl-0 mb-0 list-unstyled col-4 text-white">
          <li>
            <div class="flex-content-vc">
              <span class="icon-v2 instagram"></span>

              <div class="ml-2">
                <span>{{ restaurant.instagram }}</span>
              </div>
            </div>
          </li>

          <li>
            <div class="flex-content-vc">
              <span class="icon-v2 facebook"></span>

              <div class="ml-2">
                <span>{{ restaurant.facebook }}</span>
              </div>
            </div>
          </li>

          <li>
            <div class="flex-content-vc">
              <span class="icon-v2 twitter"></span>

              <div class="ml-2">
                <span>{{ restaurant.twitter }}</span>
              </div>
            </div>
          </li>

          <li>
            <div class="flex-content-vc">
              <span class="icon-v2 tiktok"></span>

              <div class="ml-2">
                <span>{{ restaurant.tiktok }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div *ngIf="dataLoaded && !isDesktop" appAddClassIfMobile="has-navbar-top" class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-6 col-sm-6 col-xl-3">
      <div class="card mb-3">
        <div class="card-body">
          <div class="image">
            <div class="d-flex flex-wrap">
              <div *ngIf="locations.length > 0" class="ml-auto mb-2">
                <select
                  [(ngModel)]="activeLocationId"
                  class="select-locations custom-select custom-select-no-border text-right"
                  (ngModelChange)="goToAdmin($event)"
                >
                  <option disabled [value]="locationId">
                    {{ restaurant.address }}
                  </option>

                  <option *ngFor="let location of locations" [value]="location.locationIndex">
                    {{ location.address }}
                  </option>
                </select>
              </div>
            </div>

            <div class="image-preview background">
              <app-image-picker (save)="setBackgroundImage($event)">
                <app-restaurant-img
                  [images]="restaurant.backgroundImages"
                  [alt]="restaurant.secondNameView"
                ></app-restaurant-img>
              </app-image-picker>
            </div>

            <app-image-picker *ngIf="!isImagePresent" (save)="setBackgroundImage($event)">
              <button class="btn btn-dark btn-block mt-3">
                Add Location Image
              </button>
            </app-image-picker>

            <button
              *ngIf="isImagePresent"
              class="btn btn-dark btn-block mt-3"
              (click)="removeBackgroundImage()"
            >
              Remove Location Image
            </button>
          </div>

          <div class="image mt-3">
            <button
              class="btn btn-dark btn-block"
              (click)="onQRClick()"
            >
              Download QR
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-sm-6 col-xl-3">
      <div class="card mb-3">
        <div class="card-body">
          <div class="image mb-3">
            <div class="image-description mt-0">
              <span class="title-standard">All logos require transparent .png format</span>
            </div>
          </div>

          <div class="image">
            <div class="flex-content-hc">
              <div class="image-container image-container-avatar flex-content-vc-hc">
                <app-image-picker accept="png" (save)="setAvatar($event)">
                  <img
                    *ngIf="!restaurant.details.avatar"
                    [src]="defaultAvatar"
                    [alt]="restaurant.secondNameView"
                  >

                  <div *ngIf="restaurant.details.avatar" class="p-3">
                    <img
                      appBlurImg
                      [path]="restaurant.details.avatar.path"
                      [options]="{
                        w: restaurant.details.avatar.width,
                        h: restaurant.details.avatar.height
                      }"
                      [alt]="restaurant.secondNameView"
                    >
                  </div>
                </app-image-picker>
              </div>
            </div>

            <ng-container *ngIf="!restaurant.details.avatar">
              <app-image-picker accept="png" (save)="setAvatar($event)">
                <button class="btn btn-dark btn-block mt-3">
                  Add White Logo
                </button>
              </app-image-picker>
            </ng-container>

            <button
              *ngIf="restaurant.details.avatar"
              class="btn btn-dark btn-block mt-3"
              (click)="removeAvatar()"
            >
              Remove
            </button>
          </div>

          <div class="image mt-4">
            <div class="flex-content-vc-hc">
              <div class="image-container image-container-logo">
                <app-image-picker accept="png" (save)="setLogo($event)">
                  <div
                    *ngIf="!restaurant.details.logo"
                    class="image-placeholder flex-content-vc-hc"
                  >
                    <span class="default-logo default-logo-light"></span>
                  </div>

                  <div *ngIf="restaurant.details.logo" class="image-preview">
                    <img
                      appBlurImg
                      [path]="restaurant.details.logo.path"
                      [options]="{
                        w: restaurant.details.logo.width,
                        h: restaurant.details.logo.height
                      }"
                      [alt]="restaurant.secondNameView"
                    />
                  </div>
                </app-image-picker>
              </div>
            </div>

            <ng-container *ngIf="!restaurant.details.logo">
              <app-image-picker accept="png" (save)="setLogo($event)">
                <button class="btn btn-dark btn-block mt-3">
                  Add White or Color Logo
                </button>
              </app-image-picker>
            </ng-container>

            <button
              *ngIf="restaurant.details.logo"
              class="btn btn-dark btn-block mt-3"
              (click)="removeLogo()"
            >
              Remove
            </button>
          </div>

          <div class="image mt-4">
            <div class="flex-content-vc-hc">
              <div class="image-container image-container-logo-dark">
                <app-image-picker accept="png" (save)="setLogoDark($event)">
                  <div
                    *ngIf="!restaurant.details.logoDark"
                    class="image-placeholder flex-content-vc-hc"
                  >
                    <span class="default-logo default-logo-dark"></span>
                  </div>

                  <div *ngIf="restaurant.details.logoDark" class="image-preview">
                    <img
                      appBlurImg
                      [path]="restaurant.details.logoDark.path"
                      [options]="{
                        w: restaurant.details.logoDark.width,
                        h: restaurant.details.logoDark.height
                      }"
                      [alt]="restaurant.secondNameView"
                    />
                  </div>
                </app-image-picker>
              </div>
            </div>

            <ng-container *ngIf="!restaurant.details.logoDark">
              <app-image-picker accept="png" (save)="setLogoDark($event)">
                <button class="btn btn-dark btn-block mt-3">
                  Add Dark or Color Logo
                </button>
              </app-image-picker>
            </ng-container>

            <button
              *ngIf="restaurant.details.logoDark"
              class="btn btn-dark btn-block mt-3"
              (click)="removeLogoDark()"
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #restaurantEditModalRef>
  <app-restaurant-edit-modal [restaurant]="restaurant"></app-restaurant-edit-modal>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IsLoggedIn } from '../../../decorators/is-logged-in.decorator';
import { LocalStorageValue } from '../../../decorators/local-storage-value.decorator';
import { BooleanLocalStorage } from '../../../local-storage/boolean-local-storage';
import { LocalStorageKey } from '../../../models/enums/local-storage-key';

@Component({
  selector: 'app-navbar-top',
  templateUrl: './navbar-top.component.html',
  styleUrls: ['../navbar.shared.scss']
})
export class NavbarTopComponent implements OnInit {
  @Input() title: string;
  @Input() menuHours = false;

  @Output() titleClick = new EventEmitter<boolean>();
  @Output() navbarClick = new EventEmitter<boolean>();
  @Output() openMenuEdit = new EventEmitter<boolean>();

  @IsLoggedIn() isLoggedIn: boolean;

  @LocalStorageValue(BooleanLocalStorage, LocalStorageKey.IS_EDIT_MODE, {restaurant: true, user: true})
  isEditModeLocalStorage: BooleanLocalStorage;

  isEditMode: boolean;

  constructor() {
  }

  ngOnInit() {
    this.isEditMode = this.isEditModeLocalStorage.getItem();
  }

  openMenu() {
    this.openMenuEdit.emit(true);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Favorite } from '../../../domains/favorite';
import { RestaurantSimple } from '../../../domains/restaurant-simple';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { FavoriteStatus } from '../../../models/enums/favorite-status';
import { ViewType } from '../../../models/enums/view-type';
import { LocationFavorites } from '../../../models/location-favorites';
import { Picker } from '../../../models/picker';
import { PickerConfig } from '../../../models/picker-config';
import { PickerGroup } from '../../../models/picker-group';
import { SearchResult } from '../../../models/search-result';
import { UnsplashImage } from '../../../models/unsplash-image';
import { NotificationService } from '../../../services/notification.service';
import { UnsplashService } from '../../../services/unsplash.service';
import { ViewService } from '../../../services/view.service';

@Component({
  selector: 'app-favorites-list',
  templateUrl: './favorites-list.component.html',
  styleUrls: ['./favorites-list.component.scss']
})
export class FavoritesListComponent implements OnInit, OnDestroy {
  @Input() locationFavorites: LocationFavorites;
  @Input() searchResult: SearchResult;
  @Input() cityName: string;
  @Input() favoriteList: boolean;

  @Output() setPreviewSlideRestaurant = new EventEmitter<RestaurantSimple>();
  @Output() restaurantNameClick = new EventEmitter<boolean>();
  @Output() desktopRestaurantNameClick = new EventEmitter<RestaurantSimple>();
  @Output() favoriteStatusChanged = new EventEmitter<boolean>();

  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  viewType = ViewType;
  backgroundImage: UnsplashImage;
  foodFavoritePicker = new Picker();

  favoriteStatus = FavoriteStatus;

  constructor(
    private viewService: ViewService,
    private unsplashService: UnsplashService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });

    const sidenav = document.querySelector('.user-sidenav');
    sidenav?.classList.add('d-none');

    const hamburger = document.querySelector('.sidenav-user');
    hamburger?.classList.add('d-none');

    this.locationFavorites?.restaurantFavorites?.forEach(restaurantFavorites => {
      restaurantFavorites.favorites.forEach(favorites => {
        favorites.view.type = ViewType.COLUMNS;
      });
    });

    const pictureGroup = new PickerGroup('');
    pictureGroup.addOption(FavoriteStatus.NOT_EATEN, 'Uneaten');
    pictureGroup.addOption(FavoriteStatus.TRY_ME, 'To do');
    pictureGroup.addOption(FavoriteStatus.EATEN, 'Done');
    pictureGroup.addOption(FavoriteStatus.FAVORITE, 'Favorite');
    const linksGroup = new PickerGroup('');

    this.foodFavoritePicker = new Picker(plainToClass(PickerConfig, {
      groups: [pictureGroup, linksGroup]
    }));
  }

  ngOnDestroy(): void {
    const sidenav = document.querySelector('.user-sidenav');
    sidenav?.classList.remove('d-none');

    const hamburger = document.querySelector('.sidenav-user');
    hamburger?.classList.remove('d-none');
  }

  onFavoriteClick(favorite: Favorite, newViewType: ViewType, restaurant: RestaurantSimple) {
    this.viewService.setViewTypeAndScrollToPhantomView(favorite.view, newViewType);

    if (newViewType === ViewType.CLASSIC) {
      this.locationFavorites.setFavoriteFoodsViewColumnExcept(favorite);

      this.setPreviewSlideRestaurant.emit(restaurant);
    } else if (newViewType === ViewType.COLUMNS) {
      this.setPreviewSlideRestaurant.emit(null);
    }
  }

  onRestaurantClick() {
    this.restaurantNameClick.emit(true);
  }

  onDesktopRestaurantClick(event) {
    this.closeModal();

    this.desktopRestaurantNameClick.emit(event);
  }

  favoriteClicked() {
    this.notificationService.emptyFavoriteBgClicked();
  }

  onFavoriteStatusChange(favorite: Favorite, status: FavoriteStatus) {
    this.favoriteStatusChanged.emit(true);

    favorite.status = status;
  }

  @ModalClose({all: true})
  closeModal() {
  }

  protected readonly FavoriteStatus = FavoriteStatus;
}

import { Component } from '@angular/core';
import { Auth } from '../../../decorators/auth.decorator';
import { Subscribe } from '../../../decorators/subscribe.decorator';

@Component({
  selector: 'app-user-desktop-topnav',
  templateUrl: './user-desktop-topnav.component.html',
  styleUrls: [
    '../../topnav/desktop-topnav/desktop-topnav.component.scss'
  ]
})
export class UserDesktopTopnavComponent {
  @Auth()
  @Subscribe()
  openMembershipModal() {
  }
}

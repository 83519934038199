import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CurrentNavigationExtra } from '../../../decorators/current-navigation-extra.decorator';
import { CurrentUser } from '../../../decorators/current-user.decorator';
import { IsDevice } from '../../../decorators/is-device.decorator';
import { ModalClose } from '../../../decorators/modal-close.decorator';
import { Food } from '../../../domains/food';
import { Post } from '../../../domains/post';
import { User } from '../../../domains/user';
import { DeviceType } from '../../../models/enums/device-type';
import { GetstreamVerb } from '../../../models/enums/getstream-verb';
import { Paths } from '../../../models/enums/paths';
import { NavigatorService } from '../../../services/navigator.service';
import { NotificationService } from '../../../services/notification.service';
import { PostService } from '../../../services/post.service';
import { ShareService } from '../../../services/share.service';

@Component({
  selector: 'app-post-desktop',
  templateUrl: './post-desktop.component.html',
  styleUrls: [
    './../posts.component.scss'
  ]
})
export class PostDesktopComponent implements OnInit {
  @Input() posts: Post[] = [];
  @Input() user: User;
  @Input() currentPost: Post;
  @Input() username: string;

  @ViewChild('dropdownRef', {static: false, read: NgbDropdown}) dropdown: NgbDropdown;

  @CurrentUser() currentUser: User;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  @CurrentNavigationExtra('posts', {classType: Post}) postsFromState: Post[];
  @CurrentNavigationExtra('food', {classType: Food}) food: Food;
  @CurrentNavigationExtra('hasNext') hasNext: boolean;
  @CurrentNavigationExtra('page') page: number;

  restaurantPath: string;
  captionExpanded = false;
  editMode = false;
  getstreamVerb = GetstreamVerb;
  index = 0;
  isFanFoodImage: boolean;
  paths = Paths;
  hideCaption = false;
  isSubscribed = false;
  arrowHover = false;
  captionEdited: string;
  reportText = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private navigatorService: NavigatorService,
    private shareService: ShareService,
    private notificationService: NotificationService,
    private postService: PostService
  ) {
    this.isFanFoodImage = this.activatedRoute.snapshot.data['isFanFoodImage'];
  }

  ngOnInit() {
  }

  setFocus(editor: any) {
    editor.focus();
  }

  rightButtonClick() {
    if (this.editMode) {
      this.currentPost.caption = this.captionEdited;

      this.postService.editPost(this.currentPost)
        .subscribe(() => {
          this.editMode = false;
        });
    }
  }

  cancelMode() {
    this.editMode = false;
    this.captionEdited = null;
    this.reportText = '';
  }

  goToUser(username) {
    this.navigatorService.goToUser(username);
    this.closeModal();
  }

  goToFood() {
    this.navigatorService.goToUrl(
      `${this.currentPost.restaurantPath}${this.currentPost.food.profileUrl}`
    ).then();

    if (!this.isMobile) {
      this.closeModal();
    }
  }

  postEdit() {
    this.editMode = true;

    this.captionEdited = this.currentPost.caption;
  }

  postDeleted(post: Post) {
    if (!this.isMobile) {
      const index = this.posts.indexOf(post);

      if (index > -1) {
        this.posts.splice(index, 1);
      }

      this.closeModal();
    } else {
      this.navigatorService.goToUser();
    }
  }

  closeDropdown() {
    this.dropdown.close();
  }

  shareSocial(currentPost, user, social) {
    this.shareService.sharePosOnSocial(this.currentPost, user, social);
  }

  copyLink() {
    navigator.clipboard
      .writeText(`https://${environment.domain}/${this.username}/post/${this.currentPost.hash}`)
      .then();
    this.notificationService.showCopied();
  }


  previousPostClick() {
    const index = this.posts.findIndex(post => post.id === this.currentPost.id);

    if (index) {
      this.currentPost = this.posts[index - 1];
    }
  }

  nextPostClick() {
    const index = this.posts.findIndex(post => post.id === this.currentPost.id);

    if (index + 1 < this.posts.length) {
      this.currentPost = this.posts[index + 1];
    } else {
      this.closeModal();
    }
  }

  @ModalClose()
  closeModal() {
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === 'ArrowLeft') {
      this.previousPostClick();
    } else if (event.code === 'ArrowRight') {
      this.nextPostClick();
    }
  }
}

<div *ngIf="restaurant.highlights" class="content">
  <div class="summary">{{restaurant.highlights}}</div>

  <ng-container *ngIf="isSubscribed">
    <div class="d-flex justify-content-center my-4">
      <i class="icon-v2 delimiter-gold"></i>
    </div>

    <div class="summary">{{restaurant.downsides}}</div>
  </ng-container>


  <div *ngIf="!isSubscribed" class="d-flex justify-content-center my-4">
    <button
      type="button"
      class="more-btn btn-outline-light rounded-pill"
      (click)="moreClick()"
    >
      More info
    </button>
  </div>
</div>

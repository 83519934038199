import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Auth } from '../../../../decorators/auth.decorator';
import { IsDevice } from '../../../../decorators/is-device.decorator';
import { LocalStorageValue } from '../../../../decorators/local-storage-value.decorator';
import { ModalClose } from '../../../../decorators/modal-close.decorator';
import { LocationEmbed } from '../../../../domains/location-embed';
import { LocalStorage } from '../../../../local-storage/local-storage';
import { DeviceType } from '../../../../models/enums/device-type';
import { LocalStorageKey } from '../../../../models/enums/local-storage-key';
import { TourType } from '../../../../models/enums/tour-type';
import { SearchParams } from '../../../../models/search-params';
import { UnsplashImage } from '../../../../models/unsplash-image';
import { EmbedService } from '../../../../services/embed.service';
import { UnsplashService } from '../../../../services/unsplash.service';

@Component({
  selector: 'app-video-desktop-modal',
  templateUrl: './video-desktop-modal.component.html',
  styleUrls: [
    '../search-modal/search-modal.component.scss',
    '../../../navbar/navbar.shared.scss'
  ]
})
export class VideoDesktopModalComponent implements OnInit {
  @Input() type: TourType;

  @ViewChild('videoModalRef') videoModalRef: ElementRef;
  @ViewChild('videoList') videoListRef: ElementRef;

  @LocalStorageValue(SearchParams, LocalStorageKey.SEARCH_PARAMS)
  searchParamsLocalStorage: LocalStorage<SearchParams>;

  @IsDevice(DeviceType.MOBILE) isMobile: boolean;

  backgroundImage: UnsplashImage;
  TourType = TourType;
  locationEmbeds: Array<LocationEmbed>;
  hasWalkingEmbeds = false;
  hasDiningEmbeds = false;
  submitClicked = false;
  linkVideoMode = false;
  toggleInput = true;

  searchParams: SearchParams;

  constructor(
    private unsplashService: UnsplashService,
    private embedService: EmbedService
  ) {
  }

  ngOnInit(): void {
    this.searchParams = this.searchParamsLocalStorage.getItem();

    this.unsplashService.randomPhoto(true).subscribe(value => {
      this.backgroundImage = value;
    });

    this.embedService.getAllLocationEmbeds(this.searchParams.locationId, this.searchParams.locationType)
      .subscribe((embeds) => {
        this.locationEmbeds = embeds;

        this.checkEmbedExistence();
      });
  }

  @Auth()
  openVideoModal() {
    this.toggleInput = false;
  }

  onVideoAdded(embed: LocationEmbed) {
    this.locationEmbeds.unshift(embed);

    this.videoListRef?.nativeElement.scrollIntoView({ block: 'start', behavior: 'smooth' });

    this.checkEmbedExistence();

    this.linkVideoMode = false;

    this.toggleInput = true;
  }

  onDeleteWalkingEmbed(embed: LocationEmbed) {
    this.locationEmbeds = this.locationEmbeds.filter(value => value.id !== embed.id);

    this.checkEmbedExistence();
  }

  onDeleteDiningEmbed(embed: LocationEmbed) {
    this.locationEmbeds = this.locationEmbeds.filter(value => value.id !== embed.id);

    this.checkEmbedExistence();
  }

  onVideoCanceled() {
    this.linkVideoMode = false;

    this.toggleInput = true;
  }

  @ModalClose()
  close() {}

  private checkEmbedExistence() {
    this.hasWalkingEmbeds = this.locationEmbeds.some(embed => embed.tourType === this.TourType.WALKING);
    this.hasDiningEmbeds = this.locationEmbeds.some(embed => embed.tourType === this.TourType.DINING);
  }
}

import { Type } from 'class-transformer';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MenuType } from '../models/enums/menu-type';
import { RestaurantStatus } from '../models/enums/restaurant-status';
import { Image } from '../models/image';
import { HoursSectionType } from '../models/period/hours-section-type';
import { HoursSectionTypeWrapper } from '../models/period/hours-section-type-wrapper';
import { HoursSectionViewType } from '../models/period/hours-section-view-type';
import { RestaurantHoursGroup } from '../models/period/restaurant-hours-group';
import { RestaurantHoursSection } from '../models/period/restaurant-hours-section';
import { PickerGroup } from '../models/picker-group';
import { SlideGalleryFoods } from '../models/slide-gallery-foods';
import { DateTransform } from '../utils/date-transform';
import { DateUtils } from '../utils/date-utils';
import { TimeUtils } from '../utils/time-utils';
import { Hero } from './hero';
import { CityWithParent } from './locations/city-with-parent';
import { DistrictWithParent } from './locations/district-with-parent';
import { State } from './locations/state';
import { Period } from './period';
import { RestaurantDetails } from './restaurant-details';
import { RestaurantExplore } from './restaurant-explore';
import { TopFood } from './top-foods';
import { TypeCategorySimple } from './type-category-simple';
import { User } from './user';

export class RestaurantSimple extends RestaurantExplore {
  address: string;
  zipCode: string;
  phone: string;
  email: string;
  categories: string;
  website: string;
  orderingEnabled: boolean;
  country: string;
  mmmm: string;
  instagram: string;
  facebook: string;
  twitter: string;
  tiktok: string;
  status: RestaurantStatus;
  googlePhotosLink: string;
  cityAndState: string;
  enabledMenuTypes: MenuType[];
  hoursSections: RestaurantHoursSection[] = [];
  hoursSectionsView: RestaurantHoursSection[] = [];
  hoursSectionsPreview: RestaurantHoursSection[] = [];
  closedDays: string;
  price: number;
  userId: number;
  googleBackgroundImages: Array<string>;
  highlights: string;
  downsides: string;

  @Type(() => DistrictWithParent)
  district: DistrictWithParent;

  @Type(() => CityWithParent)
  city: CityWithParent;

  @Type(() => Image)
  backgroundImages: Array<Image>;

  @Type(() => Image)
  mobileBackgroundImages: Array<Image>;

  @Type(() => RestaurantDetails)
  details: RestaurantDetails;

  @Type(() => Period)
  periods: Period[] = [];

  @Type(() => User)
  creator: User;

  @Type(() => Image)
  qr: Image;

  @Type(() => TypeCategorySimple)
  typeCategory: TypeCategorySimple;

  @Type(() => Hero)
  hero: Hero;

  @Type(() => PickerGroup)
  pickerMenus: PickerGroup[] = [];

  @Type(() => TopFood)
  topFoods: Array<TopFood>;

  @Type(() => SlideGalleryFoods)
  slideGalleryFoods: SlideGalleryFoods;

  constructor() {
    super();
    this.details = new RestaurantDetails();
  }

  get explorePath(): string {
    let path;

    if (this.district) {
      const city = this.district.city;
      const county = city.county;
      const state = county.state;

      path = `${state.key.toLowerCase()}/${county.slug}`;

      if (this.district.region) {
        path += `/${this.district.region.slug}`;
      }

      path += `/${this.district.slug}`;
    } else {
      const county = this.city.county;
      const state = county.state;
      path = `${state.key.toLowerCase()}/${county.slug}`;

      if (this.city.region) {
        path += `/${this.city.region.slug}`;
      }

      path += `/${this.city.slug}`;
    }

    return `${path}/restaurants`;
  }

  get hasMenu() {
    return this.topFoods.length > 0;
  }

  get addressStreet(): string {
    const address = this.address;
    const addressArr = address.split(' ');

    addressArr.shift();

    return addressArr.join(' ');
  }

  get fullAddress(): string {
    return this.address + ' ' + this.cityAndState;
  }

  get periodsLength(): number {
    return this.hoursSectionsView.length;
  }

  get yelpCategories() {
    return this.categories?.split(',').join(', ') || '';
  }

  get isScraped() {
    return !this.isOwned && !this.creator;
  }

  get isOwned() {
    return this.status === RestaurantStatus.OWNED;
  }

  get locationSearchPath() {
    const city = this.city;
    const county = city.county;
    const state = county.state;

    return '/' + state.key.toLowerCase() + '/' + county.slug + '/' + city.slug;
  }

  get nationalPhoneNumber() {
    try {
      return parsePhoneNumber(this.phone).nationalNumber;
    } catch (error) {
      return '';
    }
  }

  get localityDisplay() {
    return this.district?.display || this.city?.display;
  }

  get state(): State {
    return this.city?.county.state || this.district?.city.county.state;
  }

  set internationalPhoneNumber(value: string) {
    this.phone = parsePhoneNumber(value, 'US').formatInternational();
  }

  periodsGroupAndSort() {
    this.hoursSections = this.getHoursSections(HoursSectionViewType.EDIT);
    this.hoursSectionsView = this.getHoursSections(HoursSectionViewType.VIEW);
    this.hoursSectionsPreview = this.getHoursSections(HoursSectionViewType.PREVIEW);
  }

  private getHoursSections(viewType: HoursSectionViewType) {
    const twoMonths = DateTransform.plus(60);

    const hoursSections: RestaurantHoursSection[] = [];

    this.periods.forEach(period => {
      if (
        viewType === HoursSectionViewType.EDIT ||
        (viewType === HoursSectionViewType.PREVIEW && period.type.isBusinessHours) ||
        (viewType === HoursSectionViewType.VIEW && (period.type.isNormalHours || period.startDate < twoMonths))
      ) {
        let sectionTitle: string;

        if (period.type.isBusinessHours) {
          sectionTitle = '';
        } else if (period.type.isNormalHours) {
          sectionTitle = period.weekDaysToString();
        } else {
          sectionTitle = 'SPECIAL';
        }

        let hoursSection: RestaurantHoursSection;

        hoursSection = hoursSections.find(value => value.index === period.sectionIndex);

        if (!hoursSection) {
          hoursSection = new RestaurantHoursSection();
          hoursSection.index = period.sectionIndex;
          hoursSection.ordering = period.sectionOrdering;

          hoursSection.type = new HoursSectionTypeWrapper(
            period.type.isNormalHours ? HoursSectionType.NORMAL_HOURS : HoursSectionType.SPECIAL_HOURS
          );

          hoursSection.title = sectionTitle;
          hoursSection.groups = [];

          hoursSections.push(hoursSection);
        }

        let restaurantHoursGroup: RestaurantHoursGroup;

        if (period.type.isBusinessHours || !period.type.isNormalHours) {
          restaurantHoursGroup = hoursSection.groups.find(value => value.index === period.sectionGroupIndex);
        }

        if (!restaurantHoursGroup) {
          restaurantHoursGroup = new RestaurantHoursGroup();
          restaurantHoursGroup.index = period.sectionGroupIndex;
          restaurantHoursGroup.ordering = period.sectionGroupOrdering;
          restaurantHoursGroup.periods = [];

          hoursSection.groups.push(restaurantHoursGroup);
        }

        restaurantHoursGroup.periods.push(period);
      }
    });

    hoursSections.forEach(restaurantHour => {
      if (restaurantHour.title === 'SPECIAL') {
        restaurantHour.setTitle();
      }

      restaurantHour.groups.forEach(restaurantHourGroup => {
        if (restaurantHourGroup.periods.length > 1) {
          if (restaurantHour.type.isNormalHours) {
            restaurantHourGroup.periods.sort((a, b) => this.sortPeriodsByTime(a, b));
          } else {
            restaurantHourGroup.periods.sort((a, b) => this.sortSpecialPeriods(a, b));
          }
        }
      });

      restaurantHour.groups.sort((a, b) => a.ordering > b.ordering ? 1 : -1);
    });

    this.closedDays = DateUtils.daysToString(this.getClosedDays());

    if (this.closedDays) {
      const closedSection = new RestaurantHoursSection();
      closedSection.title = '';
      closedSection.type = new HoursSectionTypeWrapper(HoursSectionType.CLOSED);
      closedSection.groups = [];
      closedSection.ordering = 99;
      closedSection.closedDays = this.closedDays;

      hoursSections.push(closedSection);
    }

    hoursSections.sort((a, b) => a.ordering > b.ordering ? 1 : -1);

    return hoursSections;
  }

  private sortPeriodsByTime(a: Period, b: Period, onlyStart = false) {
    if (
      !TimeUtils.isSame(a.timeStart?.hour, a.timeStart?.minute, b.timeStart?.hour, b.timeStart?.minute) ||
      onlyStart
    ) {
      return TimeUtils.compare(a.timeStart?.hour, a.timeStart?.minute, b.timeStart?.hour, b.timeStart?.minute);
    } else {
      return TimeUtils.compare(a.timeEnd?.hour, a.timeEnd?.minute, b.timeEnd?.hour, b.timeEnd?.minute);
    }
  }

  private sortSpecialPeriods(a: Period, b: Period) {
    const aDate = a.type.isClosed ? a.closedUntil : a.date;
    const bDate = b.type.isClosed ? b.closedUntil : b.date;

    if (DateUtils.isSameDay(aDate, bDate)) {
      if (a.type.isClosed && b.type.isClosed) {
        if (a.closedUntil && !b.closedUntil) {
          return 1;
        } else if (!a.closedUntil && b.closedUntil) {
          return -1;
        } else {
          return 0;
        }
      } else if (a.type.isClosed && !b.type.isClosed) {
        return 1;
      } else if (!a.type.isClosed && b.type.isClosed) {
        return -1;
      } else if (a.type.isEvent || b.type.isClosed) {
        return this.sortPeriodsByTime(a, b, true);
      } else {
        return this.sortPeriodsByTime(a, b);
      }
    } else if (aDate > bDate) {
      return 1;
    } else {
      return -1;
    }
  }

  private getClosedDays() {
    const days = [1, 2, 3, 4, 5, 6, 7];

    this.hoursSections
      .filter(hourSection => hourSection.type.isNormalHours)
      .every(section => {
        return section.groups.every(group => {
          return group.periods.every(period => {
            for (let day = period.weekDayStart; day <= period.weekDayEnd; day++) {
              const dayIndex = days.indexOf(day);

              if (dayIndex > -1) {
                days.splice(dayIndex, 1);
              }
            }

            return days.length > 0;
          });
        });
      });

    return days;
  }
}

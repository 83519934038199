<div
  [appBackgroundUrl]="
    topFood && topFood.food.images.length > 0 ? (topFood.food.images[0]?.path | imgix) : backgroundImage
  "
  [ngClass]="{'h-530px mb-1': isDesktop, 'h-100': !isDesktop}"
  class="restaurant-preview-slide d-flex card flex-column h-100 mt-0 border-radius-none"
  (mouseover)="pictureHover = true"
  (mouseleave)="pictureHover = false"
>
  <div
    [ngClass]="{
      'preview-modal-slide-gradient-desktop': !noGradient && isDesktop,
      'preview-modal-backdrop': noGradient && hoursEnabled,
      'preview-modal-slide-gradient': !isDesktop && !hoursEnabled
    }"
    class="position-absolute-0 d-flex flex-column cursor-pointer"
    (click)="goToFood(topFood?.food)"
  >
    <div
      class="text-white d-flex justify-content-center mt-5 ml-1-75rem mr-1-75rem visibility-hidden"
      (click)="titleClicked()"
    >
      <ng-container *ngIf="!restaurant.details.logo">
        <div class="text-center align-items-center cursor-pointer">
          <div class="line-height-1-3 font-size-1-85rem font-weight-600">
            {{ restaurant.nameView }}
          </div>
        </div>
      </ng-container>

      <div class="restaurant-preview-slide-logo align-self-center cursor-pointer">
        <img
          *ngIf="restaurant.details.logo"
          appBlurImg
          [path]="restaurant.details.logo.path"
          [options]="{w: restaurant.details.logo.width, h: restaurant.details.logo.height}"
          [title]="restaurant.nameView"
          [alt]="restaurant.nameView"
        />
      </div>
    </div>

    <ng-content></ng-content>

    <div
      *ngIf="!noFooter"
      [ngClass]="{'blue-shadow': !isDesktop}"
      class="street-line mt-auto"
    >
      <div *ngIf="(!topFood || topFood.food?.gallery) && isDesktop" class="row mt-3" (click)="statsClicked()">
        <div class="w-100 text-center text-white mb-0-35rem">
          <div *ngIf="!restaurant.isOwned" class="pb-0 d-flex flex-column align-items-center">
            <i
              class="icon-v2 stats opacity-30 cursor-pointer scale-0-8"
              (click)="statsClicked()"
            ></i>
          </div>

          <div
            *ngIf="!restaurant.isOwned"
            class="font-size-0-85rem z-index-1 p-0 text-white d-flex cursor-pointer text-shadow-70"
            (click)="statsClicked()"
          >
            <span class="text-center w-100">{{ restaurant.statRating | shortNumberFormat }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="!topFood || topFood.food?.gallery" class="row" (click)="openGoogleMap()">
        <div class="col-12 text-center text-white text-shadow-70 mb-0-35rem">
          <ng-container *ngIf="restaurant.city.airport">
            {{ restaurant.city.display }}
          </ng-container>

          <ng-container *ngIf="!restaurant.city.airport">
            {{ restaurant.addressStreet }}
            <span *ngIf="!restaurant.isOwned" class="font-size-0-98rem">•</span>
            <span *ngIf="!restaurant.isOwned">{{ restaurant.cityAndState }}</span>
          </ng-container>
        </div>
      </div>

      <div *ngIf="topFood && !topFood.food?.gallery" (click)="goToFood(topFood.food)">
        <div class="text-white mb-0-35rem w-100 d-flex justify-content-center">
          <div class="d-flex cursor-pointer text-shadow-70">
            <div class="text-shadow">
              <span>{{ topFood.food.title }}</span>
            </div>

            <ng-container *ngIf="topFood.favoriteCount > 0">
              <div class="street-line-heart flex-content-vc-hc">
                <span class="icon-v2 heart-white scale-055 opacity-65"></span>
              </div>

              <div class="text-shadow">
                <span>{{ topFood.favoriteCount }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-navbar-top [title]="'Favorites'"></app-navbar-top>

<div [appBackgroundUrl]="favoritesBackgroundImage" class="ios-fixed-background"></div>

<div class="container container-favorites has-navbar-both max-width min-h-100vh">
  <div class="container-backdrop backdrop-filter-brightness-0-65">
    <div class="row row-favorites mt-1">
      <ng-container *ngFor="let restaurant of restaurantsThatHaveFavorites; let i = index">
        <div
          class="col-favorite col-4 mb-1"
          (click)="onFavoriteRestaurantClick(restaurant)"
        >
          <app-div-rectangle>
            <div class="card card-favorite">
              <div
                *ngIf="restaurant.details.avatar"
                class="favorite favorite-logo flex-content-vc-hc p-3"
              >
                <img
                  appBlurImg
                  [path]="restaurant.details.avatar.path"
                  [options]="{
                    w: restaurant.details.avatar.width,
                    h: restaurant.details.avatar.height
                  }"
                  class="w-100"
                  [alt]="restaurant.secondNameView"
                >
              </div>

              <div
                *ngIf="!restaurant.details.avatar"
                class="favorite favorite-text flex-content-vc-hc"
              >
                <span class="title-standard">
                    {{ restaurant.secondNameView }}
                </span>
              </div>
            </div>
          </app-div-rectangle>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-navbar-bottom (mainMenuClick)="openFavoritesModal()"></app-navbar-bottom>

<ng-template #favoriteFoodModalRef let-c="close">
  <app-top-swipe-modal
    [title]="currentOpenRestaurant.secondNameView"
    [scale]="false"
    (headerClick)="onHeaderClick();"
  >
    <div class="row row-favorites">
      <ng-container *ngFor="let food of foods">
        <div
          class="col-favorite col-6 mb-1"
          (click)="onFavoriteClick(food)"
        >
          <app-div-rectangle>
            <div class="card-favorite card card-item-image">
              <app-food-img
                [images]="food.images"
                [width]="720"
                [height]="720"
                [alt]="food.title"
              ></app-food-img>

              <div class="card-item-image-title text-center">
                {{ food.title }}
              </div>
            </div>
          </app-div-rectangle>
        </div>
      </ng-container>
    </div>
  </app-top-swipe-modal>
</ng-template>

<ng-template #favoritesModalRef let-c="close">
  <app-share-modal appModalCloseOnClick [path]="paths.FAVORITES"></app-share-modal>
</ng-template>

import { Component } from '@angular/core';
import { IsModalOpen } from '../../../decorators/is-modal-open.decorator';
import { IsSidenavMouseOver } from '../../../decorators/is-sidenav-mouse-over.decorator';
import { ModalType } from '../../../models/enums/modal-type';
import { SidenavType } from '../../../models/enums/sidenav-type';

@Component({
  selector: 'app-desktop-topnav',
  templateUrl: './desktop-topnav.component.html',
  styles: [
  ]
})
export class DesktopTopnavComponent {
  @IsSidenavMouseOver([SidenavType.LEFT, SidenavType.RIGHT]) isSidenavLeftOrRightMouseOver: boolean;

  @IsModalOpen(ModalType.LOGIN) isLoginModalOpen: boolean;
  @IsModalOpen(ModalType.SUBSCRIBE_DESKTOP) isSubscribeModalOpen: boolean;
  @IsModalOpen(ModalType.RESTAURANT_SQUARE) isRestaurantModalOpen: boolean;
  @IsModalOpen(ModalType.FAVORITE_SQUARE) isFavoriteModalOpen: boolean;
}

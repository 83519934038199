export class SegmentedButton {
  label: string;
  value: any;
  count: number;
  icon: boolean;

  constructor(label: string, value: any, count: number = 0, icon: boolean = false) {
    this.label = label;
    this.value = value;
    this.count = count;
    this.icon = icon;
  }
}

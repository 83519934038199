export class RestaurantLocation {
  restaurantId: number;
  restaurantDisplay: string;
  locationId: number;
  locationDisplay: string;
  locationPath: string;
  stateKey: string;
  latitude: number;
  longitude: number;
  distance: number;
  disabled = false;

  get display() {
    return `${this.restaurantDisplay} - ${this.locationDisplay} ${this.stateKey}`;
  }
}

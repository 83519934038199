export enum LocalStorageKey {
  BASKET = 'BASKET_V8',
  VIEW = 'VIEW_V2',
  IS_EDIT_MODE = 'IS_EDIT_MODE_V5',
  SEARCH_PARAMS = 'SEARCH_PARAMS_V36',
  SEARCH_PARAMS_BY_GEO_LOCATION = 'SEARCH_PARAMS_BY_GEO_LOCATION_V34',
  EDIT_ROLE = 'EDIT_ROLE_V4',
  CROWDSOURCE_HAS_CHANGES = 'CROWDSOURCE_HAS_CHANGES_V2',
  RESTAURANT_HASH_INITIAL = 'RESTAURANT_HASH_INITIAL_V2',
  RESTAURANT_HASH_CURRENT = 'RESTAURANT_HASH_CURRENT_V2',
  ENTITIES = 'ENTITIES_V4',
  TEMP_USER = 'TEMP_USER',
  IS_EDIT_NOTE_SHOWED = 'IS_EDIT_NOTE_SHOWED',
  NEED_TO_UPDATE_SEARCH = 'NEED_TO_UPDATE_SEARCH',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE_V2',
  BACKGROUND_IMAGE_MOBILE = 'BACKGROUND_IMAGE_MOBILE_V2',
  SELECT_IMAGE = 'SELECT_IMAGE',
  EXPLORE_PREVIEW = 'EXPLORE_PREVIEW_V1',
  GEO_LOCATION = 'GEO_LOCATION',
  CHECKOUT = 'CHECKOUT',
  RESTAURANT_POSITION = 'RESTAURANT_POSITION_V2',
  PREVIEW_MODAL_HOURS_ENABLED = 'PREVIEW_MODAL_HOURS_ENABLED',
  BASKET_MODAL_HOURS_ENABLED = 'BASKET_MODAL_HOURS_ENABLED',
  SEARCH_MODAL_CLOSE_DISABLE = 'SEARCH_MODAL_CLOSE_DISABLE',
  DISTANCE_FILTER_MILES_DESKTOP = 'DISTANCE_FILTER_MILES_DESKTOP',
  DISTANCE_FILTER_MILES_MOBILE = 'DISTANCE_FILTER_MILES_MOBILE',
}

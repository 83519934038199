<div *ngIf="isReady" class="container px-0 position-relative d-flex justify-content-center">
  <div class="position-fixed-0 backdrop-filter-brightness-0-5 z-index-0"></div>

  <app-hours-edit-modal
    [restaurant]="userRestaurant"
    [dashboard] = true
    [ngClass]="{'d-flex justify-content-center': !isMobile}"
    class="z-index-10 w-100 h-100"
  ></app-hours-edit-modal>
</div>

<div *ngIf="isMobile" class="flex-content-vc pr-2 pointer-events-auto">
  <app-picker
    [picker]="distancePickerMobile"
    [title]="(searchResult.distanceFilterMobile || '2') + ' mi'"
    [widthByText]="true"
    classes="text-default background-none"
    (selectedValue)="updateDistance($event)"
  ></app-picker> &nbsp;

  <i class="icon-v2 arrow-down"></i>
</div>

<div *ngIf="!isMobile" class="d-flex align-items-center">
  <div
    ngbDropdown
    appNgbDropdownOnHover
    [placement]="'bottom'"
    class="d-inline-flex cursor-pointer w-100"
  >
    <div
      appHrefNone
      ngbDropdownToggle
      class="cursor-pointer hide-arrow outline-none d-inline-flex w-100"
    >
      {{ searchResult.distanceFilterDesktop ? searchResult.distanceFilterDesktop : 0.3}} Miles
    </div>

    <div ngbDropdownMenu>
      <ng-container *ngFor="let desktopDistance of desktopDistances">
        <a
          appHrefNone
          class="dropdown-item flex-content-vc"
          (click)="updateDistance(desktopDistance.value)"
        >
          <span class="flex-fill"> {{ desktopDistance.label }} miles</span>
        </a>
      </ng-container>

      <a appHrefNone class="dropdown-item flex-content-vc" (click)="goToCityRestaurants()">
        Clear
      </a>
    </div>
  </div>
</div>

<a
  appHrefNone
  class="icon-main-circle-dark-container"
>
  <app-picker
    *ngIf="isLoggedIn && isSubscribed && !isRestaurant"
    [classes]="'icon main-circle-dark'"
    [picker]="picker"
    (selectedValue)="onRecentRestaurantChanged($event)"
  ></app-picker>

  <span *ngIf="isRestaurant || isUser" class="flex-content-vc-hc w-31px" (click)="openCitySearchModal()">
    <i class="icon-v2 search-black-new"></i>
  </span>
</a>

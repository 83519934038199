import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appSearchInputDropdownWidth]'
})
export class SearchInputDropdownWidthDirective implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() popupClass: string;

  element: HTMLSelectElement;
  popupClasses: string[];

  constructor(
    element: ElementRef,
    private renderer: Renderer2
  ) {
    this.element = element.nativeElement;
  }

  ngOnInit() {
    this.popupClasses = this.popupClass.split(' ');
  }

  ngAfterViewInit() {
    this.adjustDropdownWidth();
  }

  ngAfterViewChecked() {
    this.adjustDropdownWidth();
  }

  adjustDropdownWidth() {
    const dropdownElement = document.querySelector(`ngb-typeahead-window.${this.popupClasses.join('.')}`);

    if (dropdownElement) {
      this.renderer.setStyle(dropdownElement, 'width', `${this.element.offsetWidth - 0.5}px`);
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeoLocation } from '../../../../models/geo-location';

@Component({
  selector: 'app-navbar-bottom-semicircle-notch',
  templateUrl: './navbar-bottom-semicircle-notch.component.html',
  styleUrls: ['./navbar-bottom-semicircle-notch.component.scss']
})
export class NavbarBottomSemicircleNotchComponent implements OnInit {
  @Input() rotateMainLogo: boolean;
  @Input() searchParamsGeoLocation = GeoLocation;
  @Input() isEditMode: boolean;
  @Input() isLoggedIn: boolean;
  @Input() userRestaurantsLength: number;

  @Output() menuClick = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onMenuClick() {
    this.menuClick.emit(true);
  }
}
